import { Box } from '@chakra-ui/react'

import TabbedDisplay from 'modules/TabbedDisplay'

import FullReportPage from 'routes/common/FullReportPage'
import ResearchBriefsPage from 'routes/common/FullReportPage/components/ResearchBriefs'
import Dashboard from 'routes/dashboard'

import { Panel, PanelBody, TableauEmbed } from 'components'

import { ISingleCustomPage } from 'interfaces/navigationPage.interface'

import { getMediaDefaultWidget } from 'config/common/baseModel/Media'
import { getPresentationDefaultWidget } from 'config/common/baseModel/Presentations'
import { getPressReleasesDefaultWidget } from 'config/common/baseModel/PressReleases'

import CombinationVaccinesReport from './CombinationVaccinesReports'
import { VaccinesSlugs } from './VaccinesMenu'
import {
  VaccineCampaignsPages,
  VaccinesPages,
  VaccinesResults,
} from './VaccinesPages'

const LatestNewsPage: ISingleCustomPage<VaccinesSlugs> = {
  path: '',
  component: () => {
    return (
      <>
        <Dashboard
          widgetTabs={[
            getMediaDefaultWidget({ view: VaccinesPages['Media'].views[0] }),
            getPresentationDefaultWidget({
              view: VaccinesPages['CandidatePapers'].views[0],
            }),
            getPressReleasesDefaultWidget({
              view: VaccinesPages['PressReleases'].views[0],
            }),
          ]}
        />
      </>
    )
  },
}

const EfficacyPage: ISingleCustomPage<VaccinesSlugs> = {
  path: ['results', 'historical-vaccine-performance'],
  component: () => (
    <Box p={3} overflow='scroll'>
      <Box display='flex' pos='relative'>
        <Panel overflow='hidden' zIndex='1'>
          <PanelBody p={0}>
            <iframe
              id='embed1'
              title='Dashboard'
              src='https://airfinity.shinyapps.io/efficacyMetaAnalysis/'
              style={{
                width: '1px',
                minWidth: '100%',
                height: 'calc(100vh - 125px  - 0.75rem)',
                background: 'white',
              }}
              sandbox='allow-same-origin allow-forms allow-scripts allow-downloads allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation'
              frameBorder='0'
            />
          </PanelBody>
        </Panel>
      </Box>
    </Box>
  ),
}

const Reports: ISingleCustomPage<VaccinesSlugs> = {
  path: ['reports', 'standard'],
  component: () => <FullReportPage showCategory={false} showAppFilter />,
}
const CustomReports: ISingleCustomPage<VaccinesSlugs> = {
  path: ['combination-vaccines'],
  component: () => (
    <TabbedDisplay
      tabs={[
        {
          heading: 'Read Latest Combination Vaccines Analysis',
          body: (
            <CombinationVaccinesReport title='Combination Vaccines Approval Timelines and Results' />
          ),
        },
        {
          heading: 'Combination Vaccine Results Dashboard',
          body: (
            <TableauEmbed path='/Combinationcomparativeimmunogenicity_17195769565230/Dashboard1_1' />
          ),
        },
      ]}
    />
  ),
}

const ResearchBriefs: ISingleCustomPage<VaccinesSlugs> = {
  path: ['reports', 'research-briefs'],
  component: () => <ResearchBriefsPage />,
}

const ApprovalsOverviewDashboard: ISingleCustomPage<VaccinesSlugs> = {
  path: ['approvals', 'total-per-country-vaccine'],
  component: () => (
    <TableauEmbed path='/updated_vacc_approvals/Approvals_overview' />
  ),
}

const VaccinesApprovalThisWeek: ISingleCustomPage<VaccinesSlugs> = {
  path: ['approvals', 'this-week'],
  component: () => <TableauEmbed path='/Approvals_Weekly_Updates/Dashboard1' />,
}

const BoosterCampaigns: ISingleCustomPage<VaccinesSlugs> = {
  path: ['booster-campaigns'],
  component: () => (
    <TabbedDisplay
      tabs={[
        {
          heading: 'JN.1 Regulatory Decisions',
          body: (
            <TableauEmbed
              path='/JN_1vaccineregulatorydecisions/JN_1approvals'
              height='150vh'
            />
          ),
        },
        {
          heading: 'Vaccine Campaigns Table',
          body: Object.values(VaccineCampaignsPages)[0],
        },
      ]}
    />
  ),
}

const RecentTrialsMap: ISingleCustomPage<VaccinesSlugs> = {
  path: ['trials', 'recent-trials-map'],
  component: () => (
    <TableauEmbed
      path='/Clinical_trials_COVID_Flu_RSV/Clinicaltrials_COVID'
      height='140vh'
    />
  ),
}
const LandscapeDashboard: ISingleCustomPage<VaccinesSlugs> = {
  path: ['trials', 'landscape'],
  component: () => (
    <TableauEmbed path='/COVIDtrialsfinal/Timetotal' height='140vh' />
  ),
}

const ComparativeImmunogenicityDashboard: ISingleCustomPage<VaccinesSlugs> = {
  path: ['results', 'comparative-immunogenicity'],
  component: () => (
    <TableauEmbed
      path='/Pivotalimmunogenicityresults/Dashboard2'
      height='130vh'
    />
  ),
}

const UpdatedVaccineEffectivenessTabs: ISingleCustomPage<VaccinesSlugs> = {
  path: ['results', 'updated-vaccine-effectiveness'],
  component: () => (
    <TabbedDisplay
      tabs={[
        {
          heading: 'XBB Vaccines',
          body: (
            <TableauEmbed
              path='/XBBVaccineEffectiveness/Dashboardfinal'
              height='80vh'
            />
          ),
        },
        {
          heading: 'Bivalent mRNA Vaccines ',
          body: (
            <TableauEmbed
              path='/BivalentmRNAVaccineEffectiveness/Dashboardfinal'
              height='130vh'
            />
          ),
        },
      ]}
    />
  ),
}
const HistoricalResultsDataTabs: ISingleCustomPage<VaccinesSlugs> = {
  path: ['results', 'historical-results-data'],
  component: () => (
    <TabbedDisplay
      tabs={[
        {
          heading: 'Real World',
          body: Object.values(VaccinesResults)[0],
        },
        {
          heading: 'Phase III',
          body: Object.values(VaccinesResults)[1],
        },
      ]}
    />
  ),
}
const ProgressTrackerDashboard: ISingleCustomPage<VaccinesSlugs> = {
  path: ['candidates', 'progress-tracker'],
  component: () => (
    <TabbedDisplay
      tabs={[
        {
          heading: 'Overall Progress',
          body: (
            <TableauEmbed
              path='/frontendcovidvaccinesprogresstracker/OverallProgressbyPhase_1'
              height='80vh'
            />
          ),
        },
        {
          heading: 'Metric Breakdown',
          body: (
            <TableauEmbed
              path='/frontendcovidvaccinesprogresstracker/ProgressbyMetric'
              height='80vh'
            />
          ),
        },
      ]}
    />
  ),
}

export const VaccinesCustomPages: Record<
  string,
  ISingleCustomPage<VaccinesSlugs>
> = {
  LatestNewsPage,
  Reports,
  ResearchBriefs,
  CustomReports,
  VaccinesApprovalThisWeek,
  EfficacyPage,
  RecentTrialsMap,
  ProgressTrackerDashboard,
  BoosterCampaigns,
  LandscapeDashboard,
  ApprovalsOverviewDashboard,
  ComparativeImmunogenicityDashboard,
  UpdatedVaccineEffectivenessTabs,
  HistoricalResultsDataTabs,
}
