import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { fetchReportSingle } from 'api/cms/reports/useReportSingle'
import { Bookmark, Report } from 'api/types'
import useAxios from 'api/useAxios'

type ReturnType = Report | null

function useBookmarkResolver(
  bookmark: Bookmark,
  extraProps?: UseQueryOptions<any, any>
): UseQueryResult<Report, AxiosError<any>>
function useBookmarkResolver(
  bookmark: Bookmark,
  extraProps?: UseQueryOptions<any, any>
): UseQueryResult<ReturnType, AxiosError<any>> {
  const axios = useAxios()

  return useQuery<ReturnType, AxiosError>(
    ['resolvedBookmark', bookmark],
    () => {
      if (!bookmark) {
        return null
      }

      const { app } = bookmark

      if (bookmark.type === 'report') {
        return fetchReportSingle(
          axios,
          app,
          bookmark.metadata.id,
          bookmark.metadata.extraKey !== ''
            ? bookmark.metadata.extraKey
            : undefined
        )
      }

      return Promise.reject('Bookmark type unknown')
    },
    extraProps
  )
}

export default useBookmarkResolver
