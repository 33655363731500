import {
  Box,
  Card,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import minMax from 'dayjs/plugin/minMax'
import _ from 'lodash'
import { getDates, getHasSpecie, ListItem, NOT_REPORTED, Row } from './helpers'

dayjs.extend(minMax)

const HumanMapPopover = ({ hoverInfo }: { hoverInfo: any }) => {
  const { properties } = hoverInfo.feature || {}
  const extraData = JSON.parse(properties.extraData || '{}')
  const tabsList = Object.keys(extraData).sort(
    (a, b) => extraData[b].cases - extraData[a].cases
  )

  const isCluster = properties.cluster

  const ClusterContent = () => {
    return (
      <div className='m-3 w-full flex flex-col items-center'>
        <span>
          <b>{properties.point_count}</b> Outbreaks in the Cluster{' '}
        </span>
        <span>(zoom in to see each one)</span>
      </div>
    )
  }

  const Content = ({ subtype }: { subtype: string }) => {
    const subtypeData = extraData[subtype]

    const _reportingDate = subtypeData.date
      .map((dates: any) => {
        if (Array.isArray(dates)) {
          return dates.map((date: string) => (date ? dayjs(date) : null))
        } else return dates ? dayjs(dates) : null
      })
      .flat()

    const reportingDate = getDates(_reportingDate)

    const _outbreakDates = subtypeData.outbreakDate
      .map((dates: any) => {
        if (Array.isArray(dates)) {
          return dates.map((date: string) => (date ? dayjs(date) : null))
        } else return dates ? dayjs(dates) : null
      })
      .flat()

    const outbreakDates = getDates(_outbreakDates)

    const clades = _.uniq(subtypeData?.clade?.filter((x: string) => !!x))
    const source = _.uniq(subtypeData?.source?.filter((x: string) => !!x))

    const data = [
      {
        title: 'Reporting Date',
        value: reportingDate,
      },
      {
        title: 'Start Date',
        value: outbreakDates,
      },
      {
        title: 'Country',
        value: properties?.country ?? '',
      },
      {
        title: 'Subtype',
        value: subtype ?? '',
      },
      {
        title: 'Cases',
        value: subtypeData?.cases ?? '-',
      },
      {
        title: 'Clade',
        value: clades?.join(', ') ?? '',
      },
      {
        title: 'Source',
        value: source?.join(', ') ?? '',
      },
    ]

    return (
      <Flex
        mr={3}
        rowGap={1}
        flexDir='column'
        w='full'
        overflow={'auto'}
        maxH='300px'
      >
        {data.map((row, index) => {
          if (!row.title) return null
          return <Row title={row.title} value={row.value} key={index} />
        })}
      </Flex>
    )
  }
  return (
    <Box
      shadow={'sm'}
      className='tooltip'
      style={{
        left: hoverInfo.x,
        top: hoverInfo.y,
      }}
      onMouseEnter={(e) => {
        // We hijack the event so the popover stays open while the user is on top of it.
        e.stopPropagation()
      }}
    >
      {isCluster ? (
        <ClusterContent />
      ) : (
        <Box>
          <Tabs>
            <TabList>
              {tabsList.map((tabHeader, index) => (
                <Tab key={index} value={tabHeader}>
                  {tabHeader}
                </Tab>
              ))}
            </TabList>
            <TabPanels>
              {tabsList.map((tabValue, index) => (
                <TabPanel key={index}>
                  <Content subtype={tabValue} />
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </Box>
      )}
    </Box>
  )
}

export const MapPopover = ({ hoverInfo }: { hoverInfo: any }) => {
  if (!hoverInfo) return null
  const { properties } = hoverInfo.feature || {}

  const extraData = JSON.parse(properties.extraData || '{}')

  const _reportingDate = Object.values(extraData)
    .map((spe: any) => {
      const dates = spe.date
      if (Array.isArray(dates)) {
        return dates.map((date: string) => (date ? dayjs(date) : null))
      } else return dates ? dayjs(dates) : null
    })
    .flat()

  const reportingDate = getDates(_reportingDate)

  const _outbreakDates = Object.values(extraData)
    .map((spe: any) => {
      const dates = spe.outbreakDate
      if (Array.isArray(dates)) {
        return dates.map((date: string) => (date ? dayjs(date) : null))
      } else return dates ? dayjs(dates) : null
    })
    .flat()

  const outbreakDates = getDates(_outbreakDates)

  const isCluster = properties.cluster

  const isHuman = properties.human_code === 'Human'

  const data = [
    {
      title: 'Country',
      value: hoverInfo?.properties?.country ?? '',
    },

    { title: 'Reason', value: properties?.reason_of_notification ?? '' },
    {
      title: 'Subtype',
      value: properties?.sub_strain_clean ?? '',
    },
    { title: 'Clade', value: properties?.clade ?? '' },
  ]

  const extraDataKeys = Object.keys(extraData)
  const extraDataEntries = Object.entries(extraData)
  const nExtraData = extraDataKeys?.length

  const animals = extraDataKeys.map((specie: string, index: number) => {
    const hasSpecie = getHasSpecie(specie)
    return (
      <ListItem
        key={index}
        value={hasSpecie ? specie : NOT_REPORTED}
        specie={specie}
      />
    )
  })

  const cases = extraDataEntries.map(([specie, data]: any, index: number) => {
    const hasSpecie = getHasSpecie(specie)
    if (nExtraData === 1) {
      return <ListItem key={index} value={data.cases || '-'} specie={specie} />
    }
    return (
      <ListItem
        key={index}
        value={`${hasSpecie ? specie : NOT_REPORTED}: ${data.cases || '-'}`}
        specie={specie}
      />
    )
  })

  const dead = extraDataEntries.map(([specie, data]: any, index: number) => {
    const hasSpecie = getHasSpecie(specie)
    if (nExtraData === 1) {
      return <ListItem key={index} value={data.dead || '-'} specie={specie} />
    }
    return (
      <ListItem
        key={index}
        value={`${hasSpecie ? specie : NOT_REPORTED}: ${data.dead || '-'}`}
        specie={specie}
      />
    )
  })

  const depopulated = extraDataEntries.map(
    ([specie, data]: any, index: number) => {
      const hasSpecie = getHasSpecie(specie)
      if (nExtraData === 1) {
        return (
          <ListItem
            key={index}
            value={data.killedDisposed || '-'}
            specie={specie}
          />
        )
      }
      return (
        <ListItem
          key={index}
          value={`${hasSpecie ? specie : NOT_REPORTED}: ${data.killedDisposed || '-'} `}
          specie={specie}
        />
      )
    }
  )

  const sources = extraDataEntries.map(([specie, data]: any, index: number) => {
    const sources = _.uniq(data.source) as any
    const hasSpecie = getHasSpecie(specie)
    const hasSource = sources.length > 0
    let text =
      nExtraData === 1 ? (
        sources || '-'
      ) : (
        <>
          {hasSpecie ? specie : NOT_REPORTED}: {sources || '-'}{' '}
        </>
      )
    return (
      <Box
        fontWeight={hasSource || hasSpecie ? 'semibold' : ''}
        mr={2}
        key={specie}
      >
        {text}
      </Box>
    )
  })

  const longFormat = animals.length > 3

  if (isHuman) {
    return <HumanMapPopover hoverInfo={hoverInfo} />
  }

  return (
    <Card
      shadow={'sm'}
      className='tooltip'
      style={{
        left: hoverInfo.x,
        top: hoverInfo.y,
        position: 'absolute',
        zIndex: 9999,
      }}
      onMouseEnter={(e) => {
        // We hijack the event so the popover stays open while the user is on top of it.
        e.stopPropagation()
      }}
    >
      {isCluster ? (
        <Box
          mr={4}
          w='full'
          display={'flex'}
          flexDir={'column'}
          alignItems={'center'}
        >
          <span>
            <b>{properties.point_count}</b> Outbreaks in the Cluster{' '}
          </span>
          <span>(zoom in to see each one)</span>
        </Box>
      ) : (
        <Flex
          flexDir='column'
          rowGap={1}
          mr={3}
          w='full'
          overflow={'auto'}
          maxH='300px'
          m='1rem'
        >
          <Row title={'Reporting Date'} value={reportingDate ?? '-'} />
          <Row title={'Outbreak Date'} value={outbreakDates ?? '-'} />
          <Row title={'Animal/s'} value={animals} longFormat={longFormat} />
          {data.map((row, index) => {
            if (!row.title) return null
            return <Row key={index} title={row.title} value={row.value} />
          })}

          <Flex flexDir='column' rowGap={1} overflowY={'auto'}>
            <Row
              title={'No. of Outbreaks'}
              value={properties?.nOutbreaks ?? NOT_REPORTED}
              longFormat={longFormat}
            />
            <Row title={'Cases'} value={cases} longFormat={longFormat} />
            <Row title={'Dead'} value={dead} longFormat={longFormat} />
            <Row
              title={'Depopulated'}
              value={depopulated}
              longFormat={longFormat}
            />
            <Row title={'Sources'} value={sources} longFormat={longFormat} />
          </Flex>
        </Flex>
      )}
    </Card>
  )
}
