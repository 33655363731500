import { Box, Flex, Text } from '@chakra-ui/react'
import * as cheerio from 'cheerio'
import { truncate } from 'lodash'
import { memo, useEffect, useState } from 'react'

// @ts-ignore
import { Report } from 'api/types'

import { formatUtc } from 'utils/formatDate'

type MiniCardPropTypes = {
  report: Report
  onClick: () => void
  type?: string
  isMobile?: boolean
}
const MiniCard = ({
  report,
  onClick,
  type = 'Report',
  isMobile = false,
}: MiniCardPropTypes) => {
  const [summaryText, setSummaryText] = useState<string>('')

  const title = report.title
  const date = new Date(report.date)
  const content = report.content

  useEffect(() => {
    const $ = cheerio.load(content)
    const rowElements = $('.contentbuilderBaseColumnRow')
    const summaryRow = rowElements.eq(5)
    const textContent = summaryRow.find('td').text().trim()
    setSummaryText(textContent)
  }, [content])

  return (
    <Flex
      flexDir={isMobile ? 'column-reverse' : 'row'}
      justifyContent={'space-between'}
    >
      <Flex cursor='pointer' onClick={onClick} flexDir='column'>
        <Box>
          <Flex
            mb={2}
            justifyContent='space-between'
            flexDir={isMobile ? 'column' : 'row'}
            alignItems='flex-start'
          >
            <Text fontSize={'sm'} maxW='80%' variant='body-bold'>
              {title}
            </Text>
          </Flex>
          <Box
            maxW='480px'
            color='gray1'
            fontWeight={400}
            fontSize='14px'
            lineHeight='22px'
          >
            {truncate(summaryText, { length: 250 })}
          </Box>
        </Box>
      </Flex>
      <Text color='gray1' variant='body3-bold' whiteSpace={'nowrap'}>
        {formatUtc(date, 'dd MMM yyyy')}
      </Text>
    </Flex>
  )
}

export default memo(MiniCard)
