import { useCallback, useState } from 'react'
import Map, { Layer, Source } from 'react-map-gl/maplibre'

import './styles.css'

import layers, { EmptyLayer, LayerT } from './layers'
import { FeaturesT } from '../utils/utils'
import { MapPopover } from './Popover'

const MAP_STYLE_BASE = 'https://basemaps.cartocdn.com/gl/'
const MAP_STYLE_ENDING = '-gl-style/style.json'

function getMapURL(id: string) {
  return MAP_STYLE_BASE + id + MAP_STYLE_ENDING
}

const MAP_STYLES = {
  dark: getMapURL('dark-matter-nolabels'),
  'dark-labels': getMapURL('dark-matter'),
  'light-labels': getMapURL('voyager'),
  light: getMapURL('voyager-nolabels'),
  gray: getMapURL('positron-nolabels'),
  'gray-labels': getMapURL('positron'),
}

type SourceStyleT = {
  type: 'geojson'
  cluster: boolean
  clusterMaxZoom: number
  clusterMinPoints: number
}

const SourceStyles: SourceStyleT = {
  type: 'geojson',
  cluster: true,
  clusterMaxZoom: 20,
  clusterMinPoints: 10,
}

const MapWrapper = ({
  selectedLayer,
  mapRef,
  features,
}: {
  selectedLayer: LayerT
  mapRef: any
  features: FeaturesT
}) => {
  const [hoverInfo, setHoverInfo] = useState<any>(null)
  const [iconLoaded, setIconLoaded] = useState(false)

  const onHover = useCallback((event: any) => {
    const {
      features,
      point: { x, y },
      lngLat: { lng, lat },
    } = event
    const hoveredFeature = features && features[0]

    const properties = JSON.parse(
      hoveredFeature?.properties?.properties || '{}'
    )

    // prettier-ignore
    setHoverInfo(hoveredFeature && {feature: hoveredFeature,properties, x, y, lng, lat});
  }, [])

  const onMapLoad = async (event: any) => {
    const map = event.target
    // TODO: add image from our S3 in red
    const image = await map.loadImage(
      'https://airfinity-production.s3.amazonaws.com/public/platform/Images/human.png'
    )
    map.addImage('human-icon', image.data)
    setIconLoaded(true)
  }

  function getLayer(layer: LayerT) {
    if (selectedLayer !== layer && !!selectedLayer) return EmptyLayer
    if (layer === '') return EmptyLayer
    return features[layer]
  }

  return (
    <Map
      onLoad={onMapLoad}
      initialViewState={{
        longitude: 20,
        latitude: 20,
        zoom: 1.65,
      }}
      preserveDrawingBuffer={true}
      ref={mapRef}
      id='myMap'
      interactiveLayerIds={[
        'data-humans',
        'data-mammals',
        'data-poultry',
        'data-wild-birds',
      ]}
      onMouseMove={onHover}
      mapStyle={MAP_STYLES['gray']}
    >
      {hoverInfo && <MapPopover hoverInfo={hoverInfo} />}
      {iconLoaded && (
        <Source id='data-humans' {...SourceStyles} data={getLayer('Human')}>
          <Layer {...layers.LayerHumans} />
          <Layer {...layers.ClusterHumans} />
        </Source>
      )}

      <Source id='data-mammals' data={getLayer('Mammals')} {...SourceStyles}>
        <Layer {...layers.CircleMammals} />
        <Layer {...layers.ClusterMammals} />
      </Source>

      <Source id='data-poultry' data={getLayer('Poultry')} {...SourceStyles}>
        <Layer {...layers.CirclePoultry} />
        <Layer {...layers.ClusterPoultry} />
      </Source>
      <Source
        id='data-wild-birds'
        data={getLayer('Wild Birds')}
        {...SourceStyles}
      >
        <Layer {...layers.CircleWildBirds} />
        <Layer {...layers.ClusterWildBirds} />
      </Source>
    </Map>
  )
}

export default MapWrapper
