export const CountryGroupings = [
  {
    displayName: 'G7',
    countries: ['CAN', 'FRA', 'DEU', 'ITA', 'JPN', 'GBR', 'USA'],
    value: 'G7',
  },
  {
    displayName: 'G20',
    countries: [
      'ARG',
      'AUS',
      'BRA',
      'CAN',
      'CHN',
      'FRA',
      'DEU',
      'IND',
      'IDN',
      'ITA',
      'JPN',
      'KOR',
      'MEX',
      'RUS',
      'SAU',
      'ZAF',
      'TUR',
      'GBR',
      'USA',
    ],
    value: 'G20',
  },
  {
    displayName: 'African Region',
    countries: [
      'DZA',
      'AGO',
      'BWA',
      'BFA',
      'BDI',
      'CMR',
      'CPV',
      'CAF',
      'TCD',
      'COM',
      'COG',
      'DJI',
      'EGY',
      'GNQ',
      'ERI',
      'SWZ',
      'ETH',
      'GAB',
      'GMB',
      'GHA',
      'GIN',
      'GNB',
      'CIV',
      'KEN',
      'LSO',
      'LBR',
      'LBY',
      'MDG',
      'MWI',
      'MLI',
      'MRT',
      'MUS',
      'MAR',
      'MOZ',
      'NAM',
      'NER',
      'NGA',
      'RWA',
      'STP',
      'SEN',
      'SYC',
      'SLE',
      'SOM',
      'ZAF',
      'SSD',
      'SDN',
      'TZA',
      'TGO',
      'TUN',
      'UGA',
      'ZMB',
      'ZWE',
    ],
    value: 'AFRO',
  },
  {
    displayName: 'Region of the Americas',
    countries: [
      'ATG',
      'ARG',
      'BHS',
      'BRB',
      'BLZ',
      'BMU',
      'BOL',
      'BRA',
      'VGB',
      'CAN',
      'CHL',
      'COL',
      'CRI',
      'CUB',
      'CUW',
      'DMA',
      'DOM',
      'ECU',
      'SLV',
      'FLK',
      'GUF',
      'GRD',
      'GLP',
      'GTM',
      'GUY',
      'HTI',
      'HND',
      'JAM',
      'MTQ',
      'MEX',
      'MSR',
      'NIC',
      'PAN',
      'PRY',
      'PER',
      'PRI',
      'KNA',
      'LCA',
      'SPM',
      'VCT',
      'SXM',
      'SUR',
      'TTO',
      'TCA',
      'URY',
      'USA',
      'VEN',
      'VIR',
    ],
    value: 'AMRO',
  },
  {
    displayName: 'South-East Asia Region',
    countries: [
      'AFG',
      'BGD',
      'BTN',
      'IOT',
      'BRN',
      'KHM',
      'CHN',
      'CXR',
      'CCK',
      'IND',
      'IDN',
      'JPN',
      'KAZ',
      'KGZ',
      'LAO',
      'MAC',
      'MYS',
      'MDV',
      'MNG',
      'MMR',
      'NPL',
      'PRK',
      'PAK',
      'PHL',
      'KOR',
      'SGP',
      'LKA',
      'TWN',
      'TJK',
      'THA',
      'TKM',
      'UZB',
      'VNM',
    ],
    value: 'SEARO',
  },
  {
    displayName: 'European Region',
    countries: [
      'ALB',
      'AND',
      'ARM',
      'AUT',
      'AZE',
      'BLR',
      'BEL',
      'BIH',
      'BGR',
      'HRV',
      'CYP',
      'CZE',
      'DNK',
      'EST',
      'FIN',
      'FRA',
      'GEO',
      'DEU',
      'GRC',
      'HUN',
      'ISL',
      'IRL',
      'ISR',
      'ITA',
      'KAZ',
      'KGZ',
      'LVA',
      'LIE',
      'LTU',
      'LUX',
      'MLT',
      'MDA',
      'MCO',
      'MNE',
      'NLD',
      'MKD',
      'NOR',
      'POL',
      'PRT',
      'ROU',
      'RUS',
      'SMR',
      'SRB',
      'SVK',
      'SVN',
      'ESP',
      'SWE',
      'CHE',
      'TJK',
      'TUR',
      'TKM',
      'UKR',
      'GBR',
      'UZB',
    ],
    value: 'EURO',
  },
  {
    displayName: 'Eastern Mediterranean Region',
    countries: [
      'AFG',
      'BHR',
      'DJI',
      'EGY',
      'IRN',
      'IRQ',
      'JOR',
      'KWT',
      'LBN',
      'LBY',
      'MDV',
      'MLT',
      'MRT',
      'MAR',
      'OMN',
      'PAK',
      'PSE',
      'QAT',
      'SAU',
      'SOM',
      'SDN',
      'SYR',
      'TUN',
      'ARE',
      'YEM',
    ],
    value: 'EMRO',
  },
  {
    displayName: 'Western Pacific Region',
    countries: [
      'AUS',
      'BRN',
      'KHM',
      'CHN',
      'CXR',
      'CCK',
      'FJI',
      'PYF',
      'GUM',
      'HKG',
      'IDN',
      'JPN',
      'KIR',
      'PRK',
      'KOR',
      'LAO',
      'MAC',
      'MYS',
      'MHL',
      'FSM',
      'MNG',
      'MMR',
      'NRU',
      'NCL',
      'NZL',
      'NIU',
      'NFK',
      'MNP',
      'PLW',
      'PNG',
      'PHL',
      'PCN',
      'WSM',
      'SGP',
      'SLB',
      'TLS',
      'TKL',
      'TON',
      'TUV',
      'VUT',
      'VNM',
      'WLF',
    ],
    value: 'WPRO',
  },
]
