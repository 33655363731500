import { Box } from '@chakra-ui/react'

import BioriskMapContainer from 'modules/Biorisk'
import PandemicDashboard from 'modules/Pandemic'
import AlertsSection from 'modules/Pandemic/Alerts'
import OutbreakOverviewDashboards from 'modules/Pandemic/OutbreakOverview'

import SpikeMap from 'routes/apps/biorisk/SpikeMap'
import FullReportPage from 'routes/common/FullReportPage'
import NewsletterPage from 'routes/common/FullReportPage/components/NewsletterPage'
import ResearchBriefsPage from 'routes/common/FullReportPage/components/ResearchBriefs'
import AiSearch from 'routes/common/ai-search'

import { RShinyEmbed } from 'components'

import { ISingleCustomPage } from 'interfaces/navigationPage.interface'

import AvianInfluenzaReport from 'config/common/baseModel/biorisk/AvianInfluenzaReport'

import { BioriskSlugs } from './BioriskMenu'

const Reports: ISingleCustomPage<BioriskSlugs> = {
  path: ['reports', 'reports'],
  component: () => <FullReportPage showCategory={false} />,
}

const ResearchBriefs: ISingleCustomPage<BioriskSlugs> = {
  path: ['reports', 'research-briefs'],
  component: () => <ResearchBriefsPage />,
}

const MainDashboard: ISingleCustomPage<BioriskSlugs> = {
  path: [''],
  component: () => <PandemicDashboard />,
}

const RestrictionsRisk: ISingleCustomPage<BioriskSlugs> = {
  path: null,
  component: () => (
    <Box mt='2rem'>
      <RShinyEmbed path='lockdown_risk_index_weekly' />
    </Box>
  ),
}

const OutbreakTracker: ISingleCustomPage<BioriskSlugs> = {
  path: ['outbreak-overview'],
  component: OutbreakOverviewDashboards,
}
const Alerts: ISingleCustomPage<BioriskSlugs> = {
  path: ['alerts'],
  component: () => <AlertsSection fullPage />,
}
const DailyBriefing: ISingleCustomPage<BioriskSlugs> = {
  path: ['daily-briefing'],
  component: () => <NewsletterPage />,
}

const BioriskSpikeMap: ISingleCustomPage<BioriskSlugs> = {
  path: ['global-disease-resurgence'],
  component: () => <SpikeMap />,
}

const AvianInfluenza: ISingleCustomPage<BioriskSlugs> = {
  path: ['avian-influenza'],
  component: () => <AvianInfluenzaReport title='Avian Influenza Report' />,
}

const BioriskCSLMap: ISingleCustomPage<BioriskSlugs> = {
  path: ['hpai-zoonotic-map'],
  component: () => <BioriskMapContainer />,
}
const AiDashboard: ISingleCustomPage<BioriskSlugs> = {
  path: 'ai',
  component: () => <AiSearch />,
}

export const BioriskCustomPages: Record<
  string,
  ISingleCustomPage<BioriskSlugs>
> = {
  Alerts,
  Reports,
  MainDashboard,
  RestrictionsRisk,
  OutbreakTracker,
  ResearchBriefs,
  AiDashboard,
  BioriskSpikeMap,
  DailyBriefing,
  AvianInfluenza,
  BioriskCSLMap,
}
