import { Box, CloseButton, Heading, Text } from '@chakra-ui/react'
import * as cheerio from 'cheerio'
import { useEffect, useRef, useState } from 'react'

import { Report } from 'api/types'

import { formatUtc } from 'utils/formatDate'

interface ModalPropTypes {
  report: Report
  handleClose: () => void
  type?: string
}

const Modal = ({ handleClose, report }: ModalPropTypes) => {
  const [htmlContent, setHtmlContent] = useState<string>('')
  const renderDivRef = useRef<HTMLDivElement>(null)
  const hrefToRemove = '/%%email_id%%/h/%%internal.tracker_url_hash%%'

  const title = report.title
  const date = formatUtc(new Date(report.date), 'dd MMMM yyyy')

  useEffect(() => {
    // scroll to top whenever new report is opened
    if (renderDivRef.current) {
      renderDivRef.current.scrollTop = 0
    }

    try {
      // load html content
      const $ = cheerio.load(report.content)

      // remove last 7 table containers
      $('table.contentbuilderBaseColumnRow').slice(-7).remove()

      // loop through each <a> tag and perform a simple string replace
      $('a').each((_, element) => {
        const href = $(element).attr('href')
        if (href && href.includes(hrefToRemove)) {
          const newHref = href.replace(hrefToRemove, '')
          $(element).attr('href', newHref)
        }
      })

      // set new modifed html in state
      const modifiedHtml = $.html()
      setHtmlContent(modifiedHtml)
    } catch (e) {
      // if error occurred with parsing fallback to original report content
      console.error('An error occured when parsing HTML content: ' + e)
      setHtmlContent(report.content)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report.date, report.title])

  return (
    <>
      <Box w='full'>
        <Box bg='gray4' px={8} py={5}>
          <CloseButton
            onClick={handleClose}
            position='absolute'
            right={5}
            top={3}
            _hover={{ bg: 'none' }}
          />
          <Box maxW='2xl' mx='auto'>
            <Text variant='body3-bold' mb={1.5}>
              Daily Briefing
            </Text>
            <Heading variant='h3'>{title}</Heading>
            <Text variant='body' mt={2}>
              {date}
            </Text>
          </Box>
        </Box>
      </Box>
      <Box ref={renderDivRef} marginTop='1rem' overflow='scroll' px={8} py={6}>
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
      </Box>
    </>
  )
}

export default Modal
