import { Grid } from '@chakra-ui/react'

import TabbedDisplay from 'modules/TabbedDisplay'

import { TableauEmbed } from 'components'

const OutbreakOverviewDashboards = () => (
  <TabbedDisplay
    tabListWrapper={(props) => (
      <Grid templateColumns='repeat(4, 1fr)' gap={3} {...props} />
    )}
    tabs={[
      {
        heading: 'Outbreak Tracker',
        description: 'Infectious disease outbreaks over the past 4 weeks',
        body: (
          <TableauEmbed
            path='/Biorisk_Platform_NEW/Outbreaktracker'
            height={`calc(100vh - 170px)`}
          />
        ),
      },
      {
        heading: 'Global Outbreak Risk',
        description:
          'Change in global incidence of infectious diseases in the past month',
        body: (
          <TableauEmbed
            path='/Biorisk_Platform_NEW/Changeindiseaseburden'
            height={`calc(100vh - 170px)`}
          />
        ),
      },

      {
        heading: 'Country-Specific Outbreak Risk',
        description: 'Risk of country-specific outbreaks over the past 4 weeks',
        body: (
          <TableauEmbed
            path='/Biorisk_Platform_NEW/Country-specificoutbreaks'
            height={`calc(100vh - 170px)`}
          />
        ),
      },
      {
        heading: 'Disease Burden Over Time',
        description: 'Cumulative cases and deaths per million population',
        body: (
          <TableauEmbed
            path='/Biorisk_Platform_NEW/Casesdeathspermillion'
            height='185vh'
          />
        ),
      },
    ]}
  />
)

export default OutbreakOverviewDashboards
