import { Select } from 'components'
import { CountryGroupings } from '../utils/countryGroupings'
import { InputHeight } from 'components/Table/AdvancedSearch'
import { Box, FormLabel } from '@chakra-ui/react'

type OptionType = { id: string; display_name: string; disabled: boolean }

export const CountryGroupSelect = ({
  onChange,
}: {
  onChange: (countryGroups: OptionType[]) => void
}) => {
  const values = CountryGroupings.map((group) => ({
    value: group.value,
    label: group.displayName,
  }))

  return (
    <Box>
      <FormLabel mb={'-2px'} fontSize={'12px'}>
        Country groups
      </FormLabel>
      <Select
        fontWeight={300}
        isMulti
        isSearchable
        placeholder={`Search country groupings...`}
        height={InputHeight}
        options={values}
        onChange={(value: OptionType[]) =>
          onChange(
            value.map((option: any) => {
              return {
                display_name: option.label,
                id: option.value,
                disabled: option.isDisabled ?? false,
              }
            })
          )
        }
      />
    </Box>
  )
}
