import { FitToScreen } from '@carbon/icons-react'
import { Box, Button, Checkbox, Icon, Text } from '@chakra-ui/react'
import { HexagonLayer } from '@deck.gl/aggregation-layers'
import {
  AmbientLight,
  LightingEffect,
  _CameraLight,
  DirectionalLight,
} from '@deck.gl/core'
import type { PickingInfo, MapViewState } from '@deck.gl/core'
import DeckGL from '@deck.gl/react'
import { load } from '@loaders.gl/core'
import { CSVLoader } from '@loaders.gl/csv'
import { SphereGeometry } from '@luma.gl/engine'
import {
  _GlobeView,
  COORDINATE_SYSTEM,
  SimpleMeshLayer,
  GeoJsonLayer,
} from 'deck.gl'
import { useEffect, useState } from 'react'
import { Map } from 'react-map-gl/maplibre'

import { hoverTooltipHTML } from './constants'
import styles from './loader.module.css'

// Source data CSV
const DATA_URL =
  // 'https://airfinity-production.s3.amazonaws.com/public/website-content/assets/datasets/Bloomberg_data_for_spike_map.csv'
  'https://airfinity-production.s3.amazonaws.com/public/website-content/assets/datasets/Bloomberg_data_for_spike_map+(2).csv'

const ambientLight = new AmbientLight({
  color: [255, 255, 255],
  intensity: 1.0,
})
const cameraLight = new _CameraLight({
  color: [255, 255, 255],
  intensity: 0.2,
})

const directionalLight = new DirectionalLight({
  color: [255, 255, 255],
  direction: [0, 0, -1],
  intensity: 0.3,
})

const lightingEffect = new LightingEffect({
  ambientLight,
  cameraLight,
  directionalLight,
})

const INITIAL_VIEW_STATE: MapViewState = {
  longitude: -10,
  latitude: 20,
  zoom: 1,
  minZoom: 1,
  maxPitch: 40.5,
  pitch: 40.5,
}

const MAP_STYLE =
  'https://basemaps.cartocdn.com/gl/dark-matter-nolabels-gl-style/style.json'

const diseases = [
  'Cholera',
  'Dengue',
  'Measles',
  'Polio',
  'Respiratory Syncytial Virus',
  'Pertussis',
  'Influenza',
  'iGAS',
  'Tuberculosis',
  'Chickenpox',
]

export const colorRange: any[] = [
  [255, 109, 182], // Cholera
  [182, 109, 255], // Dengue
  [37, 255, 37], // Measles
  [255, 255, 109], // Polio
  [255, 87, 89], // Respiratory Syncytial Virus
  [255, 221, 237], // Pertussis
  [242, 142, 43], // Influenza
  [182, 153, 45], // iGAS
  [32, 119, 180], // Tuberculosis
  [160, 203, 232], // Chickenpox
]

function getTooltip({ object }: PickingInfo) {
  if (!object) {
    return null
  }

  const sourceData = object.points[0].source

  return {
    html: hoverTooltipHTML({ sourceData }),
    style: {
      borderRadius: '10px',
      border: '1px solid #45454f',
      fontSize: '16px',
    },
  }
}

type DataPoint = [longitude: number, latitude: number]

const EARTH_RADIUS_METERS = 6.3e6

const App = ({
  data = null,
  mapStyle = MAP_STYLE,
  colorRange,
  handleFullScreen,
  isFullScreen,
}: {
  data?: DataPoint[] | null
  mapStyle?: string
  colorRange: any
  handleFullScreen: () => void
  isFullScreen: boolean
}) => {
  const [option, setOption] = useState('plane')
  const layers = [
    option === 'globe'
      ? new SimpleMeshLayer({
          id: 'earth-sphere',
          data: [0],
          mesh: new SphereGeometry({
            radius: EARTH_RADIUS_METERS,
            nlat: 18,
            nlong: 36,
          }),
          coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
          getPosition: [0, 0, 0],
          getColor: [255, 255, 255],
        })
      : null,
    option === 'globe'
      ? new GeoJsonLayer({
          id: 'earth-land',
          data: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_land.geojson',
          // Styles
          stroked: false,
          filled: true,
          opacity: 0.1,
          getFillColor: [30, 80, 120],
        })
      : null,
    new HexagonLayer<DataPoint>({
      id: 'GridCellLayer',
      colorRange,
      coverage: 9,
      data,
      elevationRange: [1000, 20000],
      elevationScale: 100,
      extruded: true,
      getPosition: (d: any) => d.coord,
      getColorValue: (d: any) => diseases.indexOf(d[0].disease),
      getElevationValue: (d: any) => d[0].cases * 100,
      pickable: true,
      radius: 5000,
      upperPercentile: 100,
      material: {
        ambient: 1,
        diffuse: 1,
        shininess: 50,
        specularColor: [51, 51, 51],
      },
      transitions: {
        elevationScale: 10000,
      },
    }),
  ]

  return (
    <>
      <Box
        pos='absolute'
        right='1rem'
        zIndex='10'
        gap='8px'
        display='flex'
        top='1rem'
      >
        <Button
          onClick={() => setOption('globe')}
          variant={option === 'globe' ? 'ghost' : 'outline'}
          w='70px'
          h='34px'
          color='white'
        >
          Globe
        </Button>
        <Button
          onClick={() => setOption('plane')}
          variant={option === 'plane' ? 'ghost' : 'outline'}
          w='70px'
          h='34px'
          color='white'
        >
          Map
        </Button>
        <Button
          onClick={handleFullScreen}
          color='white'
          variant={isFullScreen ? 'blackYellow' : 'outline'}
          gap='8px'
        >
          <Icon as={() => <FitToScreen />} />
          {isFullScreen ? 'Exit' : 'Full Screen'}
        </Button>
      </Box>
      <DeckGL
        key={option}
        layers={layers}
        effects={[lightingEffect]}
        initialViewState={INITIAL_VIEW_STATE}
        controller={true}
        getTooltip={getTooltip}
        views={
          option === 'plane'
            ? ('' as any)
            : new _GlobeView({
                resolution: 10,
              })
        }
      >
        <Map reuseMaps mapStyle={option === 'plane' ? mapStyle : ''} />
      </DeckGL>
    </>
  )
}

const Legend = ({ onSelect, selected, markAll }: any) => {
  return (
    <Box
      pos='absolute'
      zIndex='10'
      bg='#00000090'
      width='max-content'
      borderRadius='1rem'
      left='1rem'
      top='1rem'
      padding='1rem'
      color='white'
    >
      <Box
        display='flex'
        alignContent={'center'}
        justifyContent='space-between'
        alignItems='center'
        mb={2}
      >
        <Text fontSize='md' fontWeight='semibold'>
          Diseases
        </Text>
        <Button
          ml={4}
          w='max-content'
          h='25px'
          fontSize={'10px'}
          variant='ghost'
          px={2}
          borderColor={'gray.800'}
          _hover={{
            backgroundColor: 'gray.900',
          }}
          color='white'
          onClick={markAll}
        >
          {selected.length === 0 ? 'Select' : 'Deselect'} All
        </Button>
      </Box>
      {diseases.map((disease, index) => {
        const color = colorRange[index]
        const isSelected = selected.includes(disease)
        return (
          <Box key={disease} mb='4px'>
            <Checkbox
              isChecked={isSelected}
              onChange={() => onSelect(disease)}
              colorScheme='gray'
              iconColor={`rgb(${color[0]},${color[1]},${color[2]})`}
            >
              <Text
                fontSize='sm'
                maxW='200px'
                whiteSpace='nowrap'
                textOverflow='ellipsis'
                overflow='hidden'
                bgColor={`rgb(${color[0]},${color[1]},${color[2]}, 0.3)`}
                borderRadius='4px'
                px='4px'
              >
                {disease}
              </Text>
            </Checkbox>
          </Box>
        )
      })}
    </Box>
  )
}

function arrangeItemsInCircle(
  posIndex: number,
  centerX: number,
  centerY: number,
  radius: number
) {
  let angleStep = (2 * Math.PI) / 10
  let angle = posIndex * angleStep
  const x = centerX + radius * Math.cos(angle)
  const y = centerY + radius * Math.sin(angle)
  return [x, y]
}

export default function SpikeMap() {
  const [data, setDate] = useState<any>()
  const [selected, setSelected] = useState(diseases)
  const [_colorRange, setColorRange] = useState(colorRange)

  const [isFullScreen, setFullScreen] = useState(false)

  async function fetchData() {
    const data = (await load(DATA_URL, CSVLoader)).data
    const [, ...restData] = data

    const cleanData = restData.map((item: any, index) => {
      const disease = item.column2
      return {
        disease,
        country: item.column4,
        coord: arrangeItemsInCircle(
          diseases.indexOf(disease) + 1,
          item.column6,
          item.column5,
          1
        ),
        value: item.column11.toFixed(2),
        cases: Math.log(item.column11),
        casesPer: Number(item.column12).toFixed(2),
        casesPerPrePandemic: Number(item.column13).toFixed(2),
        dateEndYear: item.column7,
        comparisonYear: item.column9,
        sources: item.column14,
        rank: item.column15,
        numCountries: item.column16,
        numCountriesIncrease: item.column17,
        pctCountriesIncrease: item.column18,
      }
    })
    setDate(cleanData)
  }

  function handleMarkAll() {
    const newColors = [...colorRange]
    if (selected.length === 0) {
      setSelected(diseases)
      diseases.forEach((_, colorIndex) => {
        if (newColors[colorIndex].length === 4) {
          newColors[colorIndex].pop()
        }
      })
      setColorRange(newColors)
    } else {
      setSelected([])
      diseases.forEach((_, colorIndex) => {
        if (newColors[colorIndex].length === 3) {
          newColors[colorIndex].push(0)
        }
      })
      setColorRange(newColors)
    }
  }

  function handleSelect(disease: any) {
    const newList = [...selected]
    const newColors = [..._colorRange]
    const index = selected.indexOf(disease)
    const colorIndex = diseases.indexOf(disease)
    if (index >= 0) {
      newList.splice(index, 1)
      if (newColors[colorIndex].length === 3) {
        newColors[colorIndex].push(0)
      }
    } else {
      newList.push(disease)
      if (newColors[colorIndex].length === 4) {
        newColors[colorIndex].pop()
      }
    }
    setSelected(newList)
    setColorRange(newColors)
  }

  function handleFullScreen() {
    if (isFullScreen) {
      const _document = document as any
      if (_document.exitFullscreen) {
        _document.exitFullscreen()
      } else if (_document.webkitExitFullscreen) {
        /* Safari */
        _document.webkitExitFullscreen()
      } else if (_document.msExitFullscreen) {
        /* IE11 */
        _document.msExitFullscreen()
      }
    } else {
      const elem = document.getElementById('map') as any
      if (elem.requestFullscreen) {
        elem.requestFullscreen()
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen()
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen()
      }
    }

    setFullScreen((x) => !x)
  }

  useEffect(() => {
    fetchData()
  }, [])

  if (!data)
    return (
      <Box
        h='50vh'
        display='flex'
        justifyContent='center'
        alignItems='center'
        flexDir='column'
        gap='1rem'
      >
        <Box className={styles.loader} />
        <Box>Loading Map Data...</Box>
      </Box>
    )

  return (
    <Box mt='1rem' m='auto' display='flex' flexDir='column' maxW='100vw'>
      <DescriptionText />
      <Box
        bg='#2c363d'
        pos='relative'
        h='78vh'
        borderRadius='1rem'
        overflow='hidden'
        id='map'
      >
        <Legend
          onSelect={handleSelect}
          selected={selected}
          markAll={handleMarkAll}
        />
        <App
          data={data}
          colorRange={_colorRange}
          handleFullScreen={handleFullScreen}
          isFullScreen={isFullScreen}
        />
      </Box>
    </Box>
  )
}

const DescriptionText = () => {
  return (
    <>
      <Text fontSize='lg' fontWeight='semibold' my='8px'>
        Infectious disease resurgence post-pandemic
      </Text>
      <Text fontSize='16px' fontWeight='normal' mb='8px'>
        Airfinity analysis maps global resurgence in infectious diseases
        following the pandemic. This spike map displays the fold changes in
        reported cases per country pre- and post-pandemic up to 1st June 2024.
        The colours represent each disease which you can select/deselect from
        the key. For more information click{' '}
        <a
          target='_blank'
          href='https://www.airfinity.com/d/spike-map-methodology'
          style={{
            textDecoration: 'underline',
            fontWeight: 'bold',
            cursor: 'pointer',
          }}
          rel='noreferrer'
        >
          here.
        </a>
      </Text>
    </>
  )
}
