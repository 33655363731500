import { TableauEmbed } from 'components'

import { ISingleCustomPage } from 'interfaces/navigationPage.interface'

import { ObesitySlugs } from './ObesityCommercialMenu'

const Dashboard: ISingleCustomPage<ObesitySlugs> = {
  path: [''],
  component: () => (
    <TableauEmbed path='/FrontendPricingobesity/Obesitypricingdashboard' />
  ),
}
const Revenue: ISingleCustomPage<ObesitySlugs> = {
  path: ['revenue'],
  component: () => (
    <TableauEmbed path='/Revenue-Obesity/Reportedrevenue-Dashboard' />
  ),
}

const EligiblePopulation: ISingleCustomPage<ObesitySlugs> = {
  path: ['regulatory', 'eligible-population'],
  component: () => (
    <TableauEmbed path='/ObesityApprovals/EligiblePopulationDashboard_Approvals' />
  ),
}

const ApprovalsMap: ISingleCustomPage<ObesitySlugs> = {
  path: ['regulatory', 'approvals-map'],
  component: () => <TableauEmbed path='/ObesityApprovals/ObesityApprovals' />,
}

export const ObesityCustomPages: Record<
  string,
  ISingleCustomPage<ObesitySlugs>
> = {
  Dashboard,
  Revenue,
  EligiblePopulation,
  ApprovalsMap,
}
