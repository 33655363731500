import { Box, Flex } from '@chakra-ui/react'

import { BoxBase } from './Views/TableViewSelector'

export enum LayoutsT {
  table,
  dashboard,
  graph,
}

const Selector = ({
  children,
  type,
  selectedLayout,
  onClick,
}: {
  type: LayoutsT
  selectedLayout: LayoutsT
  children: any
  onClick: (e: LayoutsT) => void
}) => {
  const isSelected = selectedLayout === type
  return (
    <BoxBase
      onClick={() => onClick(type)}
      bg={isSelected ? 'secondary' : 'transparent'}
    >
      {children}
    </BoxBase>
  )
}

const LayoutSelector = ({
  selectedLayout,
  setLayout,
}: {
  selectedLayout: LayoutsT
  setLayout: (e: LayoutsT) => void
}) => {
  return (
    <Flex h='40px' alignItems={'center'} justifyContent='center' pt='1rem'>
      <Box borderBottom={'1px solid'} w='100%' h='1px' borderColor={'gray3'} />
      <Flex gap='1rem' px='1rem'>
        <Selector
          onClick={setLayout}
          selectedLayout={selectedLayout}
          type={LayoutsT.dashboard}
        >
          Dashboard
        </Selector>
        <Selector
          onClick={setLayout}
          selectedLayout={selectedLayout}
          type={LayoutsT.table}
        >
          Table
        </Selector>
        <Selector
          onClick={setLayout}
          selectedLayout={selectedLayout}
          type={LayoutsT.graph}
        >
          Graph
        </Selector>
      </Flex>
      <Box borderBottom={'1px solid'} w='100%' h='1px' borderColor={'gray3'} />
    </Flex>
  )
}

export default LayoutSelector
