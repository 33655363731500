import { INavigationMenu } from 'interfaces/navigationMenu.interface'

export type InfluenzaSlugs =
  | ''
  | 'candidates'
  | 'table'
  | 'pipeline'
  | 'progress-tracker'
  | 'science'
  | 'trials'
  | 'all-data'
  | 'recent-trials-map'
  | 'news'
  | 'candidate-papers'
  | 'reports'
  | 'standard'
  | 'combination-reports'
  | 'results'
  | 'approvals'
  | 'map'
  | 'vaccines'
  | 'non-vaccines'
  | 'publications'
  | 'research-briefs'
  | 'dashboard'
  | 'comparative-immunogenicity-dashboard'

const InfluenzaMenu: Array<INavigationMenu<InfluenzaSlugs>[]> = [
  [
    {
      title: 'Latest Information',
      slug: '',
      description:
        'The most recent news reports, press releases, publications, tweets and reports',
      createdAt: new Date(2021, 9, 8),
    },
  ],
  [
    {
      title: 'Candidates',
      slug: 'candidates',
      description:
        'See all information on influenza vaccine and treatment candidates',
      createdAt: new Date(2021, 9, 8),
      children: [
        {
          title: 'Dashboard',
          slug: 'dashboard',
          createdAt: new Date(2024, 8, 6),
        },
        {
          title: 'Progress Tracker',
          slug: 'progress-tracker',
          createdAt: new Date(2022, 5, 23),
        },
      ],
    },
    {
      title: 'Combination Reports',
      slug: 'combination-reports',
      createdAt: new Date(2024, 5, 28),
    },
    {
      title: 'Approvals',
      slug: 'approvals',
      description: '',
      createdAt: new Date(2022, 11, 29),
      children: [
        {
          title: 'Table',
          slug: 'table',
          createdAt: new Date(2023, 1, 6),
        },
        {
          title: 'Map',
          slug: 'map',
          createdAt: new Date(2022, 11, 29),
        },
      ],
    },
    {
      title: 'New Science',
      slug: 'science',
      description:
        'The latest scientific publications on influenza vaccines and treatments',
      createdAt: new Date(2021, 9, 8),
      children: [
        {
          title: 'Publications',
          slug: 'publications',
          createdAt: new Date(2021, 9, 8),
        },
      ],
    },
    {
      title: 'Results',
      slug: 'results',
      createdAt: new Date(2022, 10, 23),
      children: [
        {
          title: 'Vaccine Results Table',
          slug: 'vaccines',
          createdAt: new Date(2022, 10, 23),
        },
        {
          title: 'Vaccine Comparative Immunogenicity Dashboard',
          slug: 'comparative-immunogenicity-dashboard',
          createdAt: new Date(2024, 8, 6),
        },
        {
          title: 'Non-Vaccine Results Table',
          slug: 'non-vaccines',
          createdAt: new Date(2022, 10, 23),
        },
      ],
    },
    {
      title: 'Trials',
      slug: 'trials',
      description:
        'List of clinical trials involving influenza vaccines and treatments',
      createdAt: new Date(2021, 9, 8),
      children: [
        {
          title: 'All Data',
          slug: 'all-data',
          createdAt: new Date(2021, 9, 8),
        },
        {
          title: 'Recent Trials Map',
          slug: 'recent-trials-map',
          createdAt: new Date(2022, 2, 29),
        },
      ],
    },
    {
      title: 'Reports',
      slug: 'reports',
      description: 'In-depth insights on topics relevant to Influenza',
      createdAt: new Date(2021, 9, 12),
      children: [
        {
          title: 'Reports',
          slug: 'standard',
          createdAt: new Date(2021, 9, 8),
        },
        {
          title: 'Research Briefs',
          slug: 'research-briefs',
          createdAt: new Date(2024, 1, 25),
        },
      ],
    },
  ],
]

export { InfluenzaMenu }
