import { CircleLayer, LayerProps, SymbolLayer } from 'react-map-gl/maplibre'

export const EmptyLayer = { type: 'FeatureCollection', features: [] }

export type LayerT = 'Poultry' | 'Mammals' | 'Wild Birds' | 'Human' | ''

export const colors = {
  Human: '#AE1212',
  Mammals: '#149880',
  'Wild Birds': '#f5a647',
  Poultry: '#774e20',
} as { [key in LayerT]: string }

const getCircleLayout = (color: string) => ({
  'circle-radius': 7,
  'circle-stroke-width': 2,
  'circle-color': 'transparent',
  'circle-stroke-color': color,
})

const sharedLayout: LayerProps['layout'] = {
  'text-field': '{point_count_abbreviated}',
  'text-size': 14,
  'text-offset': [0, 1.2],
  'icon-allow-overlap': true,
  'text-allow-overlap': true,
}

const LayerHumans: SymbolLayer = {
  id: 'data-humans',
  layout: {
    'icon-image': 'human-icon', // Use the custom icon added to the map
    'icon-size': 0.2, // Adjust the icon size as needed
    'icon-allow-overlap': true,
  },
  type: 'symbol',
  source: 'point',
}
const ClusterHumans: LayerProps = {
  id: 'cluster-count-humans',
  type: 'symbol',
  source: 'point',
  filter: ['has', 'point_count'],
  layout: sharedLayout,
  paint: {
    'text-color': '#000',
  },
}

const ClusterMammals: LayerProps = {
  id: 'cluster-count-mammals',
  type: 'symbol',
  source: 'point',
  filter: ['has', 'point_count'],
  layout: sharedLayout,
  paint: {
    'text-color': '#000',
  },
}

const CircleMammals: CircleLayer = {
  id: 'data-mammals',
  type: 'circle',
  source: 'point',
  paint: getCircleLayout(colors.Mammals),
}

const ClusterWildBirds: LayerProps = {
  id: 'cluster-count-wild-birds',
  type: 'symbol',
  source: 'point',
  filter: ['has', 'point_count'],
  layout: sharedLayout,
  paint: {
    'text-color': '#000',
  },
}

const CircleWildBirds: CircleLayer = {
  id: 'data-wild-birds',
  type: 'circle',
  paint: getCircleLayout(colors['Wild Birds']),
  source: 'maplibre',
}

const ClusterPoultry: LayerProps = {
  id: 'cluster-count-poultry',
  type: 'symbol',
  source: 'point',
  filter: ['has', 'point_count'],
  layout: sharedLayout,
  paint: {
    'text-color': '#000',
  },
}

const CirclePoultry: CircleLayer = {
  id: 'data-poultry',
  type: 'circle',
  paint: getCircleLayout(colors.Poultry),
  source: 'maplibre',
}

export default {
  LayerHumans,
  ClusterHumans,
  ClusterMammals,
  CircleMammals,
  ClusterWildBirds,
  CircleWildBirds,
  ClusterPoultry,
  CirclePoultry,
}
