import dayjs from 'dayjs'
import minMax from 'dayjs/plugin/minMax'
import _ from 'lodash'
import { FilterKeys, FilterType, RawFiltersT } from '..'
import useAxios from 'api/useAxios'

dayjs.extend(minMax)

type FilterOptions = {
  id: number | string
  display_name: string
  disabled: boolean
}
export type Human_Code_T = 'Poultry' | 'Human' | 'Wild Birds' | 'Mammals'
export interface DataT {
  area: string
  country: string
  cases: string | null
  clade: string | null
  date_reporting: string
  date_onset_export: string
  disease: string
  id: number
  latitude: string
  longitude: string
  outbreak_id: string | null
  csl_categorisation: string | null
  dead: string | null
  killed_disposed: string | null
  publication_urls: any[]
  reason_of_notification: string | null
  species: string
  species_af_supracategory: string
  sub_strain_clean: string
  human_code: Human_Code_T
  farm_unit: string
  source?: string
}

export interface FeatureT {
  type: string
  geometry: {
    type: string
    coordinates: [number, number]
  }
  properties: DataT
}

export type FilterT = {
  id: number | string
  display_name: string
  disabled: boolean
}

export interface CollectionsT {
  Poultry: FeatureT[]
  Human: FeatureT[]
  'Wild Birds': FeatureT[]
  Mammals: FeatureT[]
}
export interface FeaturesT {
  Poultry: { type: string; features: FeatureT[] }
  Human: { type: string; features: FeatureT[] }
  'Wild Birds': { type: string; features: FeatureT[] }
  Mammals: { type: string; features: FeatureT[] }
}
// TODO: this function needs to be clean and simplified, is too complex as of now.
export function getCollections(data: DataT[]): FeaturesT {
  const groups = _.groupBy(data, (item) => item.outbreak_id || 'human')

  const { human, ...animals } = groups

  const animalUpdated = Object.keys(animals).map((item) => {
    const firstOutbreak = groups[item][0]
    const extraData = groups[item].reduce((acc, curr) => {
      const current = acc[curr.species]
      if (!!current) {
        return {
          ...acc,
          [curr.species]: {
            date: [...(current.date || []), curr.date_reporting],
            outbreakDate: [
              ...(current.outbreakDate || []),
              curr.date_onset_export,
            ],
            cases: (current.cases || 0) + (curr?.cases ?? 0),
            dead: (current.dead || 0) + (curr.dead ?? 0),
            source: [...(current.source || []), curr.source],
            killedDisposed:
              (current.killedDisposed || 0) + (curr.killed_disposed ?? 0),
          },
        }
      }
      return {
        ...acc,
        [curr.species]: {
          date: [curr.date_reporting],
          outbreakDate: [curr.date_onset_export],
          source: [curr.source],
          cases: curr.cases,
          dead: curr.dead,
          killedDisposed: curr.killed_disposed,
        },
      }
    }, {} as any)

    return {
      ...firstOutbreak,
      nOutbreaks: 1,
      extraData,
    }
  })
  const groupedHumans = _.groupBy(
    human,
    (item) => `${item.latitude}-${item.longitude}`
  )
  const humanUpdated = Object.keys(groupedHumans).map((item) => {
    const firstHuman = groupedHumans[item][0]

    const extraData = groupedHumans[item].reduce((acc, curr) => {
      const current = acc[curr.sub_strain_clean]
      if (current) {
        current.date.push(curr.date_reporting)
        current.outbreakDate.push(curr.date_onset_export)
        current.cases += curr.cases ?? 0
        current.dead += curr.dead ?? 0
        current.source.push(curr.source)
        current.killedDisposed += curr.killed_disposed ?? 0
        return acc
      }
      acc[curr.species] = {
        date: [curr.date_reporting],
        outbreakDate: [curr.date_onset_export],
        cases: curr.cases,
        dead: curr.dead,
        source: [curr.source],
        killedDisposed: curr.killed_disposed,
      }
      return acc
    }, {} as any)

    return {
      ...firstHuman,
      cases: 0,
      extraData,
    }
  })

  const allOutbreaksUpdated = [...humanUpdated, ...animalUpdated] as DataT[]

  const groupedData = allOutbreaksUpdated.reduce(
    (acc, outbreak) => {
      const featureStruct = getFeatureStruct(outbreak)
      const type = outbreak.human_code
      return {
        ...acc,
        [type]: [...(acc?.[type] || []), featureStruct],
      }
    },
    {
      Poultry: [],
      Human: [],
      'Wild Birds': [],
      Mammals: [],
    } as CollectionsT
  )

  return {
    Poultry: { type: 'FeatureCollection', features: groupedData.Poultry },
    Human: { type: 'FeatureCollection', features: groupedData.Human },
    Mammals: { type: 'FeatureCollection', features: groupedData.Mammals },
    'Wild Birds': {
      type: 'FeatureCollection',
      features: groupedData['Wild Birds'],
    },
  }
}

function getFeatureStruct(outbreak: DataT) {
  const { longitude, latitude } =
    outbreak.human_code === 'Human'
      ? { ...outbreak }
      : jiggleCoordinates(+outbreak.latitude, +outbreak.longitude)
  return {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [longitude, latitude],
    },
    properties: outbreak,
  }
}

function jiggleCoordinates(lat: number, lng: number) {
  // Convert latitude and longitude from degrees to radians
  const latRad = (lat * Math.PI) / 180
  const lngRad = (lng * Math.PI) / 180

  // Define the distance to move (100 meters)
  const distance = 1000

  // Earth's radius in meters
  const earthRadius = 6378137

  // Random angle in radians
  const randomAngle = Math.random() * 2 * Math.PI

  // Calculate new latitude in radians
  const newLatRad = latRad + (distance / earthRadius) * Math.cos(randomAngle)

  // Calculate change in longitude, dependent on latitude
  const newLngRad =
    lngRad +
    ((distance / earthRadius) * Math.sin(randomAngle)) / Math.cos(latRad)

  // Convert the new latitude and longitude from radians back to degrees
  const newLat = (newLatRad * 180) / Math.PI
  const newLng = (newLngRad * 180) / Math.PI

  return { latitude: newLat, longitude: newLng }
}

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined
}

export function getDates(dates: (dayjs.Dayjs | null)[] | (dayjs.Dayjs | null)) {
  if (!Array.isArray(dates)) {
    return dayjs(dates)?.format('YYYY-MM-DD')
  }
  const _dates = dates.filter(notEmpty)
  const elements = _dates.length
  if (elements === 0) return 'Not Reported'
  const oldestDate = dayjs.min(_dates)?.format('YYYY-MM-DD')
  const newestDate = dayjs.max(_dates)?.format('YYYY-MM-DD')

  if (oldestDate === newestDate) return newestDate

  return `${oldestDate} to ${newestDate}`
}

export function getFilterOptions(
  filters: RawFiltersT | undefined,
  filterKey: string
): FilterType[] {
  return filters
    ? [
        { display_name: 'All', id: 'all', disabled: false },
        ...filters[filterKey as FilterKeys]
          .map((x: any) => {
            return {
              display_name: x.display_name,
              id: x.id,
              disabled: x.disabled,
            }
          })
          .sort((a: FilterType, b: FilterType) => {
            return a.display_name.localeCompare(b.display_name)
          }),
      ]
    : []
}

export const formatSelection = (selection: FilterType[]) => {
  if (selection) {
    return (selection.length === 1 && selection[0].id === 'all') ||
      selection.length === 0
      ? 'all'
      : selection.map((selected) => selected.id)
  } else return []
}

export function objectToQueryString(obj: object) {
  const params = new URLSearchParams()

  Object.entries(obj).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      // Handle arrays
      value.forEach((item) => params.append(key, item))
    } else if (dayjs.isDayjs(value)) {
      // Check if the value is a dayjs object
      params.append(key, value.format('YYYY-MM-DD'))
    } else if (value !== null && value !== undefined) {
      // Ensure nulls/undefined are not added
      params.append(key, value.toString())
    }
  })

  return params.toString()
}

export const filterSelection = (
  selection: FilterType[],
  filter: FilterOptions[]
): FilterType[] => {
  if (selection.length === 0) {
    return []
  }

  if (selection.some((item) => item.id === 'all')) {
    return [{ display_name: 'All', id: 'all', disabled: false }]
  }

  const filtersNotDisabled = filter.filter((filter) => !filter.disabled)

  return selection.filter((selected) =>
    filtersNotDisabled.some((filter) => filter.id === selected.id)
  )
}

export async function fetchFilters(
  apiClient: ReturnType<typeof useAxios>,
  category: string,
  selectedCountries: FilterType[],
  selectedSubTypes: FilterType[],
  selectedClades: FilterType[],
  selectedSources: FilterType[],
  selectedSpecies: FilterType[],
  startDate: Date | null,
  endDate: Date | null
) {
  const payload = {
    category,
    selected_countries: formatSelection(selectedCountries),
    selected_subtypes: formatSelection(selectedSubTypes),
    selected_clades: formatSelection(selectedClades),
    selected_sources: formatSelection(selectedSources),
    selected_species: formatSelection(selectedSpecies),
  }

  const res = await apiClient.post('/csl-filters/', payload)

  return res.data.filters
}
