import { Box } from '@chakra-ui/react'
import { LayerT, colors } from './layers'

export default function Legend({
  handleClick,
  selectedLayer,
}: {
  handleClick: (e: LayerT) => void
  selectedLayer: LayerT
}) {
  return (
    <Box
      position='absolute'
      bottom={3}
      left={3}
      backgroundColor='white'
      zIndex={10}
      borderRadius={'md'}
      shadow={'md'}
      px={2}
      py={2}
    >
      {Object.keys(colors).map((item, index) => {
        const isHuman = item === 'Human'
        const boxColor = (colors as any)[item]
        return (
          <Box
            key={index}
            display={'flex'}
            p={1}
            gap={4}
            alignItems={'center'}
            cursor={'pointer'}
            backgroundColor={item === selectedLayer ? 'gray.200' : ''}
            _hover={{
              backgroundColor: 'gray.200',
            }}
            onClick={() => handleClick(item as LayerT)}
          >
            {isHuman ? (
              <img
                src={'/csl-icons/person.svg'}
                width={16}
                height={16}
                alt='person'
              />
            ) : (
              <Box
                style={{ border: `3px solid ${boxColor}` }}
                width={'16px'}
                height={'16px'}
                rounded={'full'}
              />
            )}
            {item}
          </Box>
        )
      })}
    </Box>
  )
}
