import { Tabs, Tab, TabList, Tag } from '@chakra-ui/react'
import { createContext, useState } from 'react'

export type DrawerContentContextContentType = { [key: number]: number }
interface DrawerContentContextTypes {
  content: DrawerContentContextContentType
  setContent: (e: any) => void
}

const initialContent = { 1: 0 }

export const DrawerContent = createContext<DrawerContentContextTypes>({
  content: initialContent,
  setContent: (e: typeof initialContent) => {},
})

export const DetailDrawerTabs = ({
  tabs,
  children,
}: {
  tabs: string[]
  children: any
}) => {
  const [content, setContent] = useState<{ [key: number]: number }>(
    initialContent
  )

  return (
    <DrawerContent.Provider value={{ content, setContent }}>
      <Tabs
        isFitted
        flex={1}
        overflow='hidden'
        display='flex'
        flexDirection='column'
      >
        <TabList
          minHeight='46px'
          borderBottom='none'
          overflow='auto'
          overflowY='hidden'
        >
          {tabs.map((tab, i) => {
            return (
              <Tab
                key={i}
                zIndex={1}
                whiteSpace='nowrap'
                _focus={{
                  outline: 'none',
                }}
              >
                {tab}{' '}
                {i > 0 && (
                  <Tag
                    bg={!!content[i] ? 'yellow.500' : 'yellow.200'}
                    whiteSpace={'nowrap'}
                    rounded='lg'
                    ml='4px'
                    fontSize={'xs'}
                    display='flex'
                    justifyContent={'center'}
                    p='0'
                  >
                    {content[i]}
                  </Tag>
                )}
              </Tab>
            )
          })}
        </TabList>
        {children}
      </Tabs>
    </DrawerContent.Provider>
  )
}
