import { Box, FormLabel, Grid } from '@chakra-ui/react'
import {
  FilterKeys,
  FilterKeysPropType,
  FilterType,
  FiltersT,
  RawFiltersT,
} from '..'
import _ from 'lodash'
import { Select } from 'components'
import { CountryGroupSelect } from './CountryGroupSelect'
import { InputHeight } from 'components/Table/AdvancedSearch'
import { getFilterOptions } from '../utils/utils'

const Filters = ({
  filterLoading,
  filterKeys,
  filters,
  filtersState,
  handleCountryGroupChange,
  handleFilterChange,
}: {
  filterLoading: boolean
  filterKeys: FilterKeysPropType
  filters: RawFiltersT | undefined
  filtersState: FiltersT
  handleCountryGroupChange: (
    newCountryGroup: {
      id: string
      display_name: string
      disabled: boolean
    }[]
  ) => void
  handleFilterChange: (key: FilterKeys, value: FilterType[]) => void
}) => {
  return (
    <Box>
      {filterLoading ? (
        <Box>Loading</Box>
      ) : (
        <Grid
          mb={2}
          gap={2}
          gridTemplateColumns={[
            'repeat(3, 1fr)',
            'repeat(3, 1fr)',
            'repeat(3, 1fr)',
          ]}
        >
          {filterKeys.slice(0, -1).map((filterKey) => (
            <Box key={filterKey}>
              <FormLabel mb={'-2px'} fontSize={'12px'}>
                {_.upperFirst(filterKey)}
              </FormLabel>
              <Select
                fontWeight={300}
                isSearchable
                placeholder={`Search ${filterKey}...`}
                value={filtersState[filterKey].map((state) => {
                  return {
                    label: state.display_name,
                    value: state.id,
                    isDisabled: state.disabled ?? false,
                  }
                })}
                isMulti={true}
                height={InputHeight}
                onChange={(x: any) => {
                  handleFilterChange(
                    filterKey,
                    x.map((option: any) => {
                      return {
                        display_name: option.label,
                        id: option.value,
                        disabled: option.isDisabled ?? false,
                      }
                    })
                  )
                }}
                options={getFilterOptions(filters, filterKey).map((option) => {
                  return {
                    label: option.display_name,
                    value: option.id,
                    isDisabled:
                      typeof option.disabled !== 'undefined'
                        ? option.disabled
                        : false,
                  }
                })}
              />
            </Box>
          ))}
          <CountryGroupSelect onChange={handleCountryGroupChange} />
        </Grid>
      )}
    </Box>
  )
}

export default Filters
