import { Box } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useRef, useState } from 'react'

import { FullPageError } from 'components'

import useAxios from 'api/useAxios'

import Legend from './Map/Legend'
import { CountryGroupings } from './utils/countryGroupings'
import { LayerT } from './Map/layers'
import {
  DataT,
  FeaturesT,
  fetchFilters,
  filterSelection,
  formatSelection,
  getCollections,
  objectToQueryString,
} from './utils/utils'
import MapWrapper from './Map'
import TimeSlider from './Map/TimeSlider'
import { addDays, format } from 'date-fns'
import Filters from './Filters/Filters'
import { subYears } from 'date-fns'
import _ from 'lodash'
import { MapRef } from 'react-map-gl'

export type FilterT = {
  id: number | string
  display_name: string
  disabled: boolean
}

export type FilterType = {
  display_name: string
  id: string | number
  disabled: boolean
}

const filterKeys = [
  'countries',
  'clades',
  'sources',
  'species',
  'subtypes',
  'countryGroups',
] as const

export type FilterKeysPropType = typeof filterKeys
export type FilterKeys = (typeof filterKeys)[number]

export type FiltersT = Record<FilterKeys, FilterType[]>
export type RawFiltersT = Record<FilterKeys, FilterT[]>

export const DefaultStartDate = subYears(new Date(), 1)

const INITIAL_DATE = '2011-03-22'

const getQueryString = (
  formattedStartDate: string,
  formattedEndDate: string,
  filterPayload: string
) => {
  return `/lzdb/csl/csl-zoonoses/?view=csl_zoonoses_avian&start_date=${formattedStartDate}&end_date=${formattedEndDate}&${filterPayload}`
}

const BioriskMapDashboard = () => {
  const mapRef = useRef<MapRef>(null)

  const [selectedLayer, setLayer] = useState<LayerT>('')
  const [features, setFeatures] = useState<FeaturesT>()
  const [isPlaying, setIsPlaying] = useState(false)

  const overallStartDate = new Date(INITIAL_DATE)
  const overallEndDate = new Date()

  const [filterOptions, setFiltersOptions] = useState<RawFiltersT>({
    countries: [],
    subtypes: [],
    clades: [],
    sources: [],
    species: [],
    countryGroups: [],
  })
  const [selectedFiltersState, setSelectedFiltersState] = useState<
    FiltersT & { dataType: string; startDate: Date; endDate: Date }
  >({
    countries: [],
    clades: [],
    sources: [],
    species: [],
    subtypes: [],
    countryGroups: [],
    dataType: 'avian',
    startDate: new Date(INITIAL_DATE),
    endDate: addDays(new Date(INITIAL_DATE), 14),
  })
  const [filterPayload, setFilterPayload] = useState<string>('')

  const handleFilterChange = (key: FilterKeys, value: FilterType[]) => {
    setSelectedFiltersState((prevState) => ({
      ...prevState,
      [key]: value,
    }))
    if (key === 'countries' && value.length === 0) {
      setSelectedFiltersState((prevState) => ({
        ...prevState,
        countryGroups: [],
      }))
    }
  }

  const axios = useAxios()

  const { data: mapData, error: mapError } = useQuery(
    [
      'biorisk-map-data',
      filterPayload,
      selectedFiltersState.startDate,
      selectedFiltersState.endDate,
    ],
    async () => {
      const formattedStartDate = format(
        selectedFiltersState.startDate,
        'yyyy-MM-dd'
      )
      const formattedEndDate = format(
        selectedFiltersState.endDate,
        'yyyy-MM-dd'
      )

      const response = await axios.get(
        getQueryString(formattedStartDate, formattedEndDate, filterPayload)
      )
      return response.data as DataT[]
    }
  )

  function handleLegendClick(newLayer: LayerT) {
    setLayer(newLayer === selectedLayer ? '' : newLayer)
  }

  const handleCountryGroupChange = (newCountryGroup: FilterT[]) => {
    handleFilterChange('countryGroups', newCountryGroup)

    if (newCountryGroup.length === 0) {
      handleFilterChange('countries', [])
      return
    }
    const countries = newCountryGroup.reduce<string[]>((acc, group) => {
      const countryGroup = CountryGroupings.find(
        (countryGroup) => countryGroup.value === group.id
      )
      return acc.concat(countryGroup?.countries || [])
    }, [])

    const selectedCountries = filterOptions?.countries
      ?.filter((country) => {
        return countries.includes(country?.id as string) && !country.disabled
      })
      .map((country) => {
        return {
          display_name: country?.display_name,
          id: country?.id,
          disabled: country.disabled,
        }
      })
    selectedCountries && handleFilterChange('countries', selectedCountries)
  }

  const handleDateRangeChange = (newStartDate: Date, newEndDate: Date) => {
    setSelectedFiltersState((prevState) => ({
      ...prevState,
      startDate: newStartDate,
      endDate: newEndDate,
    }))
  }

  useEffect(() => {
    if (mapData) {
      const data = getCollections(mapData)
      setFeatures(data)
    }
  }, [mapData, mapData?.length])

  useEffect(() => {
    const payload = {
      category: 'avian',
      countries: formatSelection(
        selectedFiltersState.countries as FilterType[]
      ),
      subtypes: formatSelection(selectedFiltersState.subtypes as FilterType[]),
      clades: formatSelection(selectedFiltersState.clades as FilterType[]),
      sources: formatSelection(selectedFiltersState.sources as FilterType[]),
      species: formatSelection(selectedFiltersState.species as FilterType[]),
    }
    const queryString = objectToQueryString(payload)
    setFilterPayload(queryString)
  }, [selectedFiltersState])

  useEffect(() => {
    async function fetchAndSetFilters() {
      const fetchedFilters = await fetchFilters(
        axios,
        'avian',
        selectedFiltersState.countries,
        selectedFiltersState.subtypes,
        selectedFiltersState.clades,
        selectedFiltersState.sources,
        selectedFiltersState.species,
        null,
        null
      )
      setFiltersOptions(fetchedFilters)
      const newSelections: Record<FilterKeys, FilterType[]> & {
        startDate: Date
        endDate: Date
        dataType: string
      } = {
        countries: filterSelection(
          selectedFiltersState.countries,
          fetchedFilters.countries
        ),
        subtypes: filterSelection(
          selectedFiltersState.subtypes,
          fetchedFilters.subtypes
        ),
        clades: filterSelection(
          selectedFiltersState.clades,
          fetchedFilters.clades
        ),
        sources: filterSelection(
          selectedFiltersState.sources,
          fetchedFilters.sources
        ),
        species: filterSelection(
          selectedFiltersState.species,
          fetchedFilters.species
        ),
        startDate: selectedFiltersState.startDate,
        endDate: selectedFiltersState.endDate,
        dataType: 'avian',
        countryGroups: selectedFiltersState.countryGroups,
      }

      // Only update if the new selections are different from the current selections
      if (!_.isEqual(selectedFiltersState, newSelections)) {
        setSelectedFiltersState(newSelections)
      }
    }
    fetchAndSetFilters()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFiltersState])

  if (mapError) {
    return <FullPageError />
  }

  if (!features) return null

  return (
    <Box>
      <Filters
        filterKeys={filterKeys}
        filterLoading={false}
        filters={filterOptions}
        filtersState={selectedFiltersState}
        handleCountryGroupChange={handleCountryGroupChange}
        handleFilterChange={handleFilterChange}
      />
      <Box
        borderRadius={'10px'}
        position={'relative'}
        height={[
          'calc(100vh - 330px)',
          'calc(100vh - 330px)',
          'calc(100vh - 210px)',
        ]}
        overflow='hidden'
      >
        <Legend handleClick={handleLegendClick} selectedLayer={selectedLayer} />
        <MapWrapper
          mapRef={mapRef}
          selectedLayer={selectedLayer}
          features={features}
        />
      </Box>
      <TimeSlider
        startDate={selectedFiltersState.startDate}
        endDate={selectedFiltersState.endDate}
        onDateRangeChange={handleDateRangeChange}
        overallStartDate={overallStartDate}
        overallEndDate={overallEndDate}
        isPlaying={isPlaying}
        setIsPlaying={(value) => setIsPlaying(value)}
      />
    </Box>
  )
}

export default BioriskMapDashboard
