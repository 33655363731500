import { ChartColumn, TableSplit } from '@carbon/icons-react'
import { Box, Flex, Progress } from '@chakra-ui/react'
import { proxy } from 'comlink'
import { FOOTER_HEIGHT, NAV_HEIGHT } from 'constants/misc'
import {
  createElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useSearchParams } from 'react-router-dom'
import { animated, useSpring } from 'react-spring'
import instance from 'worker'

import { AuthContext } from 'contexts'
import { useTableChartFilters } from 'contexts/TableChartFilters'

import {
  FullPageError,
  PageHeader,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from 'components'
import { AdvancedFilterUpdaterProp } from 'components/Table/AdvancedSearch/useAdvancedFilters'

import { flattenPaginatedData } from 'api/helper'
import { IResponseBase } from 'api/types'
import useTableData, { mapStateFromTableState } from 'api/useTableData'
import { GoToPageUpdaterType, TableState } from 'api/useTableData/types'

import { IChart } from 'interfaces/charts.interface'
import { IModel, IModelField } from 'interfaces/model.interface'
import {
  NavigationPageComponentProps,
  ViewData,
} from 'interfaces/navigationPage.interface'
import { ValueType } from 'interfaces/valueType.interface'

import useTracking from 'tracking/useTracking'

import { getCustomSortOrderFromModel, resolveModel } from 'utils/model'
import useIsMobile from 'utils/useIsMobile'

import AutoGeneratedDashboard from './Dashboard/AutoGeneratedDashboard'
import FilterPanel from './Dashboard/FilterPanel/FilterPanel'
import LayoutSelector, { LayoutsT } from './LayoutSelector'
import SelectedDrawer from './SelectedDrawer'
import MemoTable from './TableComponent'
import { TablePageDataContext } from './TablePageProvider'
import { TableViewSelector } from './Views/TableViewSelector'
import Visualisation from './Visualisation'

const AnimatedProgress = animated(Progress)

function GenericTable<D extends Record<string, any> = Record<string, any>>({
  model: pageModel,
  app,
  views,
  page,
}: NavigationPageComponentProps<D>) {
  const [isMobile] = useIsMobile()
  const { userInfo } = useContext(AuthContext)

  const [selectedLayout, setLayout] = useState(LayoutsT.dashboard)

  const [filter, setFilter] = useState<AdvancedFilterUpdaterProp>()
  const setAdvancedFilters = useRef((_: AdvancedFilterUpdaterProp) => {})
  const goToPageRef = useRef((_: GoToPageUpdaterType) => {})
  const [tabIndex, setTabIndex] = useState<number>(0)

  const [tracking] = useTracking()
  const [queryParams] = useSearchParams()
  const viewFromUrl = queryParams.get('v')
  const defaultView =
    views.find((v) => v.airtableName === viewFromUrl) ?? views[0]

  const [selectedView, setView] = useState(defaultView)

  const { tableFilters } = useTableChartFilters()

  const model: IModel<any> = useMemo(
    () => resolveModel(pageModel, selectedView),
    [selectedView, pageModel]
  )
  const viewData: ViewData = useMemo(() => {
    return {
      airtableName: selectedView.airtableName,
      airtableBase: selectedView.airtableBase ?? 'covid',
    } as ViewData
  }, [selectedView.airtableBase, selectedView.airtableName])

  // =================================================
  // ==================== CHARTS =====================
  // =================================================

  const typesToShow = [ValueType.SINGLE, ValueType.NUMBER, ValueType.MULTI]
  const generatedCharts = model.schema.columns
    .filter((x) => typesToShow.includes(x.type ?? ValueType.TEXT))
    .map((x): IChart => {
      return {
        key: x.key,
        column: x.key,
        defaultType: x?.graphType || 'Progress',
        title: x.label,
        customDataFilter: x.customDataFilter,
      }
    })

  const chartsRaw: IChart[] = Object.values(
    generatedCharts
      .concat(model?.defaultCharts ?? [])
      .concat(page.charts ?? [])
      .reduce((acc, val) => {
        return { ...acc, [val.key]: val }
      }, {})
  )

  const charts = chartsRaw.filter(
    (d) => !model?.chartColumnBlackList?.includes(d.key) ?? true
  )

  // =================================================
  // ===================== DATA ======================
  // =================================================

  const {
    collectionDataQuery,
    tableState,
    onStateChange,
    userViewConfig,
    useUserViewsReturn,
  } = useTableData({
    page,
    view: selectedView,
  })

  const { isLoading, isError, error } = collectionDataQuery
  const flattenedPaginatedData = flattenPaginatedData(
    collectionDataQuery.data?.pages
  )
  const data = flattenedPaginatedData?.results

  const tableData = model.customTableData
    ? model.customTableData(collectionDataQuery.data)
    : data

  const shouldRenderChart =
    !app?.disableAnalytics && charts.length > 0 && !model.excludeGeneratedCharts
  const shouldAutoGenerateDashboard = Boolean(page.autoGenerateDashboard)
  const showTabs = shouldRenderChart || shouldAutoGenerateDashboard

  const exportName = `${app.name}_${model.name}_${selectedView.name}`

  const [selectedRow, setRowRaw] = useState<IResponseBase<
    Extract<keyof D, string>
  > | null>(null)

  const getPageIndexForSpecificRow = useCallback(
    (hash: string | number) => {
      if (hash) {
        const mappedTableState: TableState = mapStateFromTableState(
          tableState,
          model.schema.columns.map((x) => x.key)
        )
        const columnSchema = model?.schema?.columns?.map((column) => ({
          type: column?.type,
          key: column?.key,
        }))
        const index = instance.getPageIndexByRow({
          appEndpoint: app.endpoint,
          modelEndpoint: model.endpoint,
          viewData: viewData,
          state: mappedTableState,
          customSortOrder: getCustomSortOrderFromModel(model),
          schema: columnSchema as IModelField<any>[] | undefined,
          rowId: hash,
          pageSize: tableState.pageSize,
        })
        return index
      }
    },
    [app.endpoint, model, tableState, viewData]
  )

  useEffect(() => {
    // check that we have loaded all data
    const navigate = async () => {
      if (
        flattenedPaginatedData &&
        flattenedPaginatedData?.globalLoaded ===
          flattenedPaginatedData?.globalTotal &&
        flattenedPaginatedData?.count !== 0
      ) {
        let hash = window.location.hash.slice(1)
        hash = decodeURI(hash)

        if (hash !== '') {
          const parsedHash =
            typeof data![0].id === 'number' ? parseInt(hash) : hash
          const index = await getPageIndexForSpecificRow(parsedHash)
          if (typeof index !== 'undefined') {
            goToPageRef.current(() => {
              return index
            })
          }
          const hashData = data?.find((val) => val?.id === parsedHash)
          !isLoading && setRowRaw(hashData ?? null)
        }
      }
    }
    navigate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    flattenedPaginatedData?.globalLoaded,
    flattenedPaginatedData?.globalTotal,
    flattenedPaginatedData?.count,
    data,
  ])

  const setRow = useCallback((row: IResponseBase<string> | null) => {
    const newHashData = row
    const newHash = encodeURI(newHashData?.id?.toString() ?? '')
    // We don't want this to cause a re-render so we use history from `window`
    // Otherwise, the whole tree will re-render

    window.history.replaceState(
      null,
      '',
      newHash
        ? `#${newHash}`
        : window.location.pathname + window.location.search
    )
    // Set the state to actually handle showing the drawer
    setRowRaw(row)
  }, [])

  const handleSelectRow = useCallback(
    (data: any) => {
      const { afTopic, datePublished, link, source, title } = data
      if (page.key === 'Media') {
        tracking.mediaSelection({
          topic: afTopic,
          date: datePublished,
          section: 'table',
          source,
          title,
          link,
        })
      }
      setRow(data)
    },
    [setRow, page.key, tracking]
  )

  // Incremental Load stuff
  const globalLoaded = useMemo(
    () =>
      (!!flattenedPaginatedData ? flattenedPaginatedData.globalLoaded : 0) ?? 0,
    [flattenedPaginatedData]
  )
  const globalTotal = useMemo(
    () =>
      (!!flattenedPaginatedData ? flattenedPaginatedData.globalTotal : 0) ?? 0,
    [flattenedPaginatedData]
  )

  const incrementalLoadProgress = useSpring({
    val: globalTotal === 0 ? 0 : (globalLoaded / globalTotal) * 100,
  })

  const [incrementalLoadError, setIncrementalLoadError] = useState(false)

  useEffect(() => {
    setIncrementalLoadError(false)
    instance.onDataLoadProgressError(
      proxy(() => {
        setIncrementalLoadError(true)
      })
    )
  }, [setIncrementalLoadError, page, selectedView])
  const tableName = useMemo(
    () =>
      (useUserViewsReturn?.selectedUserViewIndex ?? 0) > 0 &&
      // We need to check the length since it takes an update for the selected index to change after a delete operation
      (userViewConfig.userViewQuery.data?.length ?? 0) >
        (useUserViewsReturn?.selectedUserViewIndex ?? 0)
        ? userViewConfig.userViewQuery.data![
            useUserViewsReturn?.selectedUserViewIndex ?? 0
          ].name
        : selectedView.name,
    [
      selectedView.name,
      useUserViewsReturn?.selectedUserViewIndex,
      userViewConfig.userViewQuery.data,
    ]
  )

  const filtersCallback = useCallback(
    (func: (e: AdvancedFilterUpdaterProp) => void) => {
      setAdvancedFilters.current = func
    },
    []
  )
  const goToPageCallback = useCallback(
    (func: (e: GoToPageUpdaterType) => void) => {
      goToPageRef.current = func
    },
    []
  )

  useEffect(() => {
    tableFilters && setAdvancedFilters.current(tableFilters)
  }, [tableFilters])

  useEffect(() => {
    setAdvancedFilters.current([])
  }, [])

  const Table = (
    <MemoTable
      tableName={tableName}
      userViewConfig={userViewConfig}
      useUserViewsReturn={useUserViewsReturn}
      data={tableData ?? []}
      handleSelectRow={handleSelectRow}
      isLoading={isLoading && (!tableData || tableData.length === 0)}
      model={model}
      onStateChange={onStateChange}
      state={tableState}
      total={flattenedPaginatedData?.count}
      exportName={exportName}
      goToPageCallback={goToPageCallback}
      callback={filtersCallback}
      isRenderingDashboard={shouldAutoGenerateDashboard && tabIndex === 0}
    />
  )

  if (isError || incrementalLoadError) {
    return <FullPageError error={error} />
  }

  const Description = () => {
    if (model.description) {
      return createElement(model.description)
    }
    return null
  }

  function handleFilters(e: AdvancedFilterUpdaterProp) {
    setFilter(e)
  }

  const isLoadingData =
    (isLoading && (!tableData || tableData.length === 0)) ||
    (globalLoaded !== globalTotal && globalLoaded < globalTotal)

  return (
    <TablePageDataContext.Provider
      value={{
        app,
        model,
        page,
        viewData,
        selectedView,
        handleFilters,
        filters: tableState.advancedFilters,
      }}
    >
      <Tabs
        display='flex'
        flexDirection='column'
        flex={1}
        pt={1}
        overflow='hidden'
        height={`calc(100vh - ${NAV_HEIGHT} - ${FOOTER_HEIGHT})`}
        maxHeight={`calc(100vh - ${NAV_HEIGHT} - ${FOOTER_HEIGHT})`}
        isLazy
        defaultIndex={0}
        variant='line'
        onChange={(index) => setTabIndex(index)}
      >
        <Flex justifyContent='space-between' mb={'-10px'} alignItems='start'>
          {!model.hideTableHeader && (
            <PageHeader
              hideQuickFilter={shouldAutoGenerateDashboard}
              title={model.name}
              isLoading={isLoadingData}
              applyFilter={handleFilters}
            />
          )}
          {showTabs && !shouldAutoGenerateDashboard && (
            <TabList
              minHeight='46px'
              float={'right'}
              borderBottom='none'
              zIndex={2}
            >
              <Tab data-cy='table-tab-data' fontSize='13px'>
                <TableSplit size={16} />
                <Box mr='6px' />
                Table
              </Tab>
              {shouldRenderChart && (
                <Tab
                  onClick={() => tracking.openPageAnalytics({})}
                  data-cy='table-tab-analytics'
                  fontSize='13px'
                >
                  <ChartColumn size={16} />
                  <Box mr='6px' />
                  Chart
                </Tab>
              )}
            </TabList>
          )}
        </Flex>
        {!shouldAutoGenerateDashboard &&
          views &&
          selectedView &&
          views.length > 0 && (
            <Box mt={isMobile ? 4 : 0}>
              <TableViewSelector
                dropdown={isMobile}
                views={views}
                selectedView={selectedView}
                handleSetView={setView ?? (() => {})}
                tableState={tableState}
                userViewConfig={userViewConfig}
                useUserViewsReturn={useUserViewsReturn}
              />
            </Box>
          )}
        {model.description && <Description />}
        {globalLoaded < globalTotal && (
          <Box position='relative' mb={5}>
            <Box pos='absolute' left={'0'} right='0' top='0.5rem'>
              <AnimatedProgress
                hasStripe
                isAnimated
                size='sm'
                colorScheme='yellow'
                bg='white'
                value={incrementalLoadProgress.val}
              />
            </Box>
          </Box>
        )}
        <SelectedDrawer
          selectedRow={selectedRow}
          model={model}
          setRow={setRow}
          viewData={selectedView}
        />
        {showTabs ? (
          <TabPanels display='flex' flex={1} overflow='hidden'>
            {shouldAutoGenerateDashboard ? (
              <TabPanel
                maxW='90vw'
                flex={1}
                overflowX='hidden'
                px={0}
                py={3}
                display='flex'
                flexDirection='column'
              >
                <FilterPanel
                  model={model}
                  tableData={tableData}
                  applyQuickFilter={handleFilters}
                  isLoading={isLoadingData}
                  views={views}
                  setView={setView}
                  selectedView={selectedView}
                  tableState={tableState}
                  userViewConfig={userViewConfig}
                  useUserViewsReturn={useUserViewsReturn}
                />
                <Box
                  minH={selectedLayout === LayoutsT.graph ? '70vh' : '600px'}
                  maxH={selectedLayout === LayoutsT.graph ? '100vh' : '1000px'}
                  mb={selectedLayout === LayoutsT.graph ? '0px' : '15px'}
                  display={selectedLayout !== LayoutsT.table ? 'block' : 'none'}
                >
                  <AutoGeneratedDashboard
                    selectedViewIndex={useUserViewsReturn.selectedUserViewIndex}
                  />
                </Box>

                <LayoutSelector
                  selectedLayout={selectedLayout}
                  setLayout={setLayout}
                />
                <Box
                  minH={selectedLayout === LayoutsT.table ? '65vh' : '500px'}
                  maxH='550px'
                  display={selectedLayout !== LayoutsT.graph ? 'block' : 'none'}
                >
                  {Table}
                </Box>
              </TabPanel>
            ) : null}
            <TabPanel flex={1} pt={2} px={0} overflow='auto'>
              {Table}
            </TabPanel>
            <TabPanel flex={1} pt={2} px={0} overflow='auto'>
              <Visualisation charts={charts} />
            </TabPanel>
          </TabPanels>
        ) : (
          Table
        )}
      </Tabs>
    </TablePageDataContext.Provider>
  )
}

export default GenericTable
