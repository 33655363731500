import { Box, Flex } from '@chakra-ui/react'
import _ from 'lodash'
import moment from 'moment'
import { useMemo, useState } from 'react'
import { Virtuoso } from 'react-virtuoso'

import { useAppRoute } from 'routes/utils'

import { InlineLoading, PageHeader } from 'components'

import { NewsLetterT } from 'api/cms/newsletters/types'
import { useNewsletters } from 'api/cms/newsletters/useNewsletters'
import { flattenPaginatedData } from 'api/helper'

import useTracking from 'tracking/useTracking'

import useIsMobile from 'utils/useIsMobile'
import useThrottle from 'utils/useThrottle'

import NothingFound from '../NothingFound'
import { SortMenu } from '../SortMenu'
import MainCard from './MainCard'
import MiniCard from './MiniCard'
import SlideModal from './SlideModal'

export const groupBriefsByMonth = (items: (NewsLetterT | undefined)[]) => {
  const groups = _.groupBy(items, (result) => {
    const value = moment(result?.date, 'YYYY-MM-DD')
      .startOf('month')
      .format('YYYY-MM')
    return value
  })
  return groups
}

const NewsletterPage = () => {
  const [isMobile] = useIsMobile()
  const [tracking] = useTracking()
  const selectedApp = useAppRoute()

  const [search, setSearch] = useState('')
  const searchString = useThrottle(search, 200)
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc')
  const [selected, setSelected] = useState<NewsLetterT | null>(null)

  const {
    data,
    isLoading,
    hasNextPage: canFetchMore,
    fetchNextPage: fetchMore,
  } = useNewsletters(selectedApp, { limit: 20 })

  const newsletters = flattenPaginatedData(data?.pages)
    ?.results as unknown as NewsLetterT[]

  const filteredRawBriefs = useMemo(() => {
    return (
      newsletters &&
      newsletters.filter((brief) =>
        ['subject'].some((key) =>
          String(brief[key as keyof typeof brief])
            .toLowerCase()
            .includes(searchString.toLowerCase())
        )
      )
    )
  }, [newsletters, searchString])

  const sortedNewsletters = useMemo(
    () => _.orderBy(filteredRawBriefs, 'datePublished', sortOrder),
    [filteredRawBriefs, sortOrder]
  )
  const groupedNewsletters = useMemo(
    () => groupBriefsByMonth(sortedNewsletters),
    [sortedNewsletters]
  )

  const groups = useMemo(
    () => Object.keys(groupedNewsletters),
    [groupedNewsletters]
  )

  return (
    <Box overflow='none'>
      <Box flex={1} pt={3} display='flex' flexDirection='column'>
        <PageHeader title={'Biorisk Daily Briefing'} />
        {/* <InputGroup>
          <ReportSearchInput
            isMobile={isMobile}
            onBlur={() =>
              searchString &&
              tracking.searchNewsletterBriefs({
                app: selectedApp,
                searchValue: searchString,
              })
            }
            showCategory={false}
            onChange={(e: any) => setSearch(e.target.value)}
          />
        </InputGroup> */}
        <SlideModal
          isMobile={isMobile}
          selected={
            selected && {
              id: selected.id,
              title: selected.subject,
              date: new Date(selected.date),
              content: selected.htmlMessage,
              summary: '',
              tableContent: selected.htmlMessage,
              report: '',
              reportName: '',
              apps: [],
            }
          }
          type={'NewsletterBrief'}
          setSelected={() => setSelected(null)}
        />
        <Box border='1px solid' borderColor='gray3' borderRadius='8px' my={6}>
          {isLoading ? (
            <Flex alignItems='center' justifyContent='center' height='68px'>
              <InlineLoading />
            </Flex>
          ) : (
            <>
              <Flex
                justifyContent='space-between'
                minHeight='68px'
                px={8}
                alignItems='center'
              >
                <SortMenu sortOrder={sortOrder} setSortOrder={setSortOrder} />
              </Flex>
              {groups.length > 0 ? (
                <Virtuoso
                  style={{ height: '70vh' }}
                  data={groups}
                  endReached={() => {
                    if (canFetchMore) {
                      fetchMore()
                      tracking.fetchedMoreNewsletters({})
                    }
                  }}
                  itemContent={(index, key) => {
                    return (
                      <MainCard isMobile={isMobile} key={index} keyStr={key}>
                        {groupedNewsletters[key]?.map((brief, i) => {
                          if (!brief) return null
                          return (
                            <MiniCard
                              isMobile={isMobile}
                              key={i}
                              type='NewsletterBrief'
                              onClick={() => {
                                tracking.openedNewsletter({
                                  ...brief,
                                })
                                setSelected(brief)
                              }}
                              report={{
                                id: brief.id,
                                title: brief.subject,
                                date: new Date(brief.date),
                                content: brief.htmlMessage,
                                summary: null,
                                tableContent: '',
                                report: '',
                                reportName: '',
                                apps: [],
                              }}
                            />
                          )
                        })}
                      </MainCard>
                    )
                  }}
                />
              ) : (
                <NothingFound searchString={searchString} briefs />
              )}
            </>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default NewsletterPage
