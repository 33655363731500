import { Play, Stop } from '@carbon/icons-react'
import {
  Box,
  Button,
  Card,
  HStack,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  VStack,
  Text,
} from '@chakra-ui/react'
import {
  addDays,
  differenceInDays,
  differenceInWeeks,
  format,
  min,
} from 'date-fns'
import { debounce } from 'lodash'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { SingleDatePicker } from 'react-dates'
import styled from 'styled-components'

import './styles.css'
import useKeyPress from 'utils/useKeyPress'

const HiddenInput = styled.div`
  .SingleDatePickerInput {
    .DateInput {
      display: none;
    }
    .SingleDatePickerInput_calendarIcon {
      padding: 0;
    }
  }
`

const DatePickerTrigger = ({
  startDate,
  endDate,
}: {
  startDate: Date
  endDate: Date
}) => (
  <Card px={2} py={1}>
    <HStack>
      <VStack gap={-1}>
        <Text fontSize={'xs'} color={'gray.600'} fontWeight={'semibold'}>
          Start date
        </Text>
        <Text>{format(startDate, 'yyyy-MM-dd')}</Text>
      </VStack>
      <VStack gap={-1}>
        <Text fontSize={'xs'} color={'gray.600'} fontWeight={'semibold'}>
          End date
        </Text>
        <Text>
          {endDate === startDate
            ? format(startDate, 'yyyy-MM-dd')
            : format(endDate, 'yyyy-MM-dd')}
        </Text>
      </VStack>
    </HStack>
  </Card>
)

const TimeSlider = ({
  endDate,
  onDateRangeChange,
  startDate,
  overallEndDate,
  overallStartDate,
  isPlaying,
  setIsPlaying,
}: {
  startDate: Date
  endDate: Date
  onDateRangeChange: (newStartDate: Date, newEndDate: Date) => void
  overallStartDate: Date
  overallEndDate: Date
  isPlaying: boolean
  setIsPlaying: (value: boolean) => void
}) => {
  // calculate initial value given through props
  const initialSliderValue = useMemo(() => {
    return Math.floor(differenceInWeeks(startDate, overallStartDate) / 2)
  }, [startDate, overallStartDate])

  const [focused, setFocused] = useState(false)
  const [sliderValue, setSliderValue] = useState(initialSliderValue)
  const [playInterval, setPlayInterval] = useState<NodeJS.Timeout | null>(null)

  const twoWeekIntervals = useMemo(() => {
    const effectiveEndDate = min([overallEndDate, new Date()])
    return Math.floor(differenceInDays(effectiveEndDate, overallStartDate) / 14)
  }, [overallStartDate, overallEndDate])

  useEffect(() => {
    setSliderValue(initialSliderValue)
  }, [startDate, endDate, overallStartDate, initialSliderValue])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedDateRangeChange = useCallback(
    debounce((newStartDate: Date, newEndDate: Date) => {
      onDateRangeChange(newStartDate, newEndDate)
    }, 500),
    [onDateRangeChange]
  )

  const updateDateRange = (value: number) => {
    const newStartDate = addDays(overallStartDate, value * 14)
    const newEndDate = addDays(newStartDate, 13)
    onDateRangeChange(newStartDate, newEndDate)
  }

  const handleSliderChange = (value: number) => {
    setSliderValue(value)
    if (isPlaying) {
      updateDateRange(value)
    } else {
      // if manually setting slider call debounced change
      const newStartDate = addDays(overallStartDate, value * 14)
      const newEndDate = addDays(newStartDate, 13)
      debouncedDateRangeChange(newStartDate, newEndDate)
    }
  }

  const handlePlayClick = () => {
    setIsPlaying(true)
    const interval = setInterval(() => {
      setSliderValue((prevValue) => {
        if (prevValue >= twoWeekIntervals) {
          clearInterval(interval)
          setIsPlaying(false)
          setPlayInterval(null)
          return prevValue
        }
        const newValue = prevValue + 1
        updateDateRange(newValue)
        return newValue
      })
    }, 300) // CHANGE DELAY
    setPlayInterval(interval)
  }

  const handleStopClick = () => {
    setIsPlaying(false)
    if (playInterval) {
      clearInterval(playInterval)
      setPlayInterval(null)
    }
  }

  useKeyPress('Escape', () => {
    if (focused) {
      setFocused(false)
    }
  })

  useEffect(() => {
    return () => {
      if (playInterval) {
        clearInterval(playInterval)
      }
    }
  }, [playInterval])

  return (
    <Box
      pos={'absolute'}
      bottom='3rem'
      style={{ zIndex: 800, transform: 'translate(-50%,0px)' }}
      w='60vw'
      left='50%'
    >
      <HStack>
        <Box mt={8} ml={3}>
          {!isPlaying ? (
            <Button
              w='80px'
              variant='yellow'
              fontSize='12px'
              onClick={handlePlayClick}
              isDisabled={isPlaying}
            >
              <Box mr={1}>
                <Play size={16} />
              </Box>
              Play
            </Button>
          ) : (
            <Button
              w='80px'
              variant='outline'
              fontSize='12px'
              onClick={handleStopClick}
              isDisabled={!isPlaying}
            >
              <Box mr={1}>
                <Stop size={16} />
              </Box>
              Stop
            </Button>
          )}
        </Box>
        <VStack w='full' mt={-6} gap={0}>
          <Box style={{ zIndex: 800 }}>
            <HiddenInput>
              <SingleDatePicker
                id='custom-date-picker'
                withPortal
                inputIconPosition='before'
                anchorDirection='right'
                hideKeyboardShortcutsPanel
                focused={focused}
                isOutsideRange={() => false}
                onDateChange={(date) => {
                  const newDate = date?.toDate() ?? new Date()
                  onDateRangeChange(newDate, newDate)
                }}
                onFocusChange={({ focused }) => setFocused(focused)}
                date={moment(startDate)}
                customInputIcon={
                  <DatePickerTrigger endDate={endDate} startDate={startDate} />
                }
              />
            </HiddenInput>
          </Box>
          <Slider
            mt={3}
            ml={3}
            aria-label='date-range-slider'
            value={sliderValue}
            min={0}
            max={twoWeekIntervals}
            step={1}
            colorScheme='yellow'
            onChange={handleSliderChange}
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb />
          </Slider>
        </VStack>
      </HStack>
    </Box>
  )
}

export default TimeSlider
